import * as React from 'react';
import AdFooter from '../../components/Ads/AdFooter';
import AdHeader from '../../components/Ads/AdHeader';
import AdPositioner from '../../components/Ads/AdPositioner';
import AdProvider from '../../components/Ads/AdProvider';
import FixedBottomRespAd from '../../components/Ads/FixedBottomRespAd';
import PreviewTargetArea from '../../components/PreviewTargetArea';
import Footer from '../../containers/Footer';
import Header from '../../containers/Header';
import LayoutContext from '../../contexts/LayoutContext';
import styled from '../../theme/styled-components';
import { Helmet } from 'react-helmet-async';
import AdPageStart from '../../components/Ads/AdPageStart';

const Content = styled.div`
  flex: 1 1 auto;
  color: ${({ theme }) => theme.app.textColor};
`;

const Page: React.FC = ({ children }) => {
  const [isMenuFixed, setIsMenuFixed] = React.useState(false);
  const [isDropdownActive, setIsDropdownActive] = React.useState(false);

  const sidebarRef = React.useRef<HTMLDivElement>(null);

  const wrapperElRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <Helmet>
        <meta name="robots" content="max-image-preview:large" />
      </Helmet>
      <LayoutContext.Provider
        value={{
          isMenuFixed,
          setIsMenuFixed,
          isDropdownActive,
          setIsDropdownActive,
          menuPositioner: wrapperElRef,
          sidebarRef,
        }}
      >
        <PreviewTargetArea id="header">
          <Header />
        </PreviewTargetArea>
        <AdPositioner />
        <AdProvider>
          <AdPageStart />
          <AdHeader />
          <Content>{children}</Content>
          <AdFooter />
          <FixedBottomRespAd />
        </AdProvider>
        <PreviewTargetArea id="footer">
          <Footer />
        </PreviewTargetArea>
        <div id="sidebar-portal" ref={sidebarRef} />
      </LayoutContext.Provider>
    </>
  );
};

export default Page;
