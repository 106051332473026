import React from 'react';
import slugify from 'slugify';
import objectToParams from 'sportnet-utilities/lib/objectToParams';
import AdContext from '../../../contexts/AdContext';
import debug from '../../../debug';
import getAdvertsEnv from '../../../utilities/adverts/getAdvertsEnv';

type IOwnProps = {};

type IProps = IOwnProps;

const adPositions = [
  '_header',
  '_footer',
  'big_1',
  'big_1_left_side',
  'big_1_right_side',
  'body_1',
  'body_2',
  'fixed_bottom_resp',
  'list_body_1',
  'list_body_1_resp',
  'list_body_2',
  'megaboard',
  'side_1',
  'side_2',
  'article_body_top_position',
  'article_body_1',
  'article_body_2',
  'article_body_3',
  'article_body_4',
  'article_body_5',
  'article_body_6',
  'article_body_7',
  'article_body_8',
  'article_body_9',
  'article_body_10',
  'article_body_11',
  'article_body_12',
  'article_body_13',
  'article_body_14',
  'article_body_15',
  'content_body_1',
  'content_body_2',
  'content_body_3',
  'content_body_4',
  'content_body_5',
  'content_body_6',
  'content_body_7',
  'content_body_8',
  'content_body_9',
  'content_body_10',
  'content_body_11',
  'content_body_12',
  'content_body_13',
  'content_body_14',
  'content_body_15',
  'homepage_tb_top',
  'article_after',
  'page_start',
] as const;

export type IAdPosition = (typeof adPositions)[number];
export type IAdverts = { [key in IAdPosition]?: string };

export interface IAdParams {
  site: string;
  siteId?: string;
  articleTemplateType?: string;
  rubric?: string;
  subrubric?: string;
  origin?: 'futbalnet' | 'svetmma' | 'sportnet'; // required
  pagetype?: 'article' | 'list' | 'table' | 'other' | 'diskusie'; // required
  unionName?: string;
  clubName?: string;
  seasonName?: string;
  competitionLevel?: string;
  competitionName?: string;
  competitionCategory?: string;
  competitionGender?: string;
  author?: string;
}

export const formatAdParams = (params: IAdParams | null) =>
  params
    ? Object.keys(params)
        .sort()
        .map((k: keyof IAdParams) => params[k])
        .filter((x) => x)
        .join(':')
    : '';

const extendAdvertsWithDebuggingInfo = (
  adverts: IAdverts,
  params: IAdParams
) => {
  const newAdverts = { ...adverts };

  Object.keys(newAdverts).forEach((k: keyof typeof newAdverts) => {
    newAdverts[k] = newAdverts[k]!.replaceAll(
      /(<script[^>]*>)([\s\S]*?)(<\/script>)/gm,
      (_, start: string, body: string, end: string) => {
        const formattedBody = body.trim().replaceAll(/\s+/gm, ' ');
        const executing = `console.log("exec ${k} script", { code: \`${formattedBody}\` });`;
        return `${start}${executing}${body}${end}`;
      }
      // `$1${executing}$2$3`,
    );

    const init = `<script>console.log("init ${k} with params:", ${JSON.stringify(
      params
    )});</script>`;

    newAdverts[k] = `${init}${newAdverts[k]}`;
  });

  return newAdverts;
};

const prepareTargetingValue = (text: string) => {
  // https://support.google.com/admanager/answer/1628457
  // https://support.google.com/admanager/answer/10020177
  return slugify(text, {
    remove: /["'=!+#*~;^()<>[\]`&]/g,
    lower: true,
    strict: true,
  }).substring(0, 40);
};

const setTargetingParams = ({
  rubric,
  subrubric,
  origin,
  pagetype,
  unionName,
  clubName,
  seasonName,
  competitionLevel,
  competitionName,
  competitionCategory,
  competitionGender,
  author,
}: IAdParams) => {
  const result: Array<{ [key: string]: any }> = [{ origin }, { pagetype }];
  if (rubric) {
    result.push({ rubric: prepareTargetingValue(rubric) });
  }
  if (subrubric) {
    result.push({ subrubric: prepareTargetingValue(subrubric) });
  }
  if (unionName) {
    result.push({ unionName });
  }
  if (clubName) {
    result.push({ clubName });
  }
  if (seasonName) {
    result.push({ seasonName });
  }
  if (competitionLevel) {
    result.push({ competitionLevel });
  }
  if (competitionName) {
    result.push({ competitionName });
  }
  if (competitionCategory) {
    result.push({
      competitionCategory,
    });
  }
  if (competitionGender) {
    result.push({
      competitionGender,
    });
  }
  if (author) {
    result.push({
      author,
    });
  }
  (window as any).smeAdvertTargeting = result;
};

const resetTargetingParams = () => {
  (window as any).smeAdvertTargeting = [];
};

const AdProvider: React.FC<IProps> = ({ children }) => {
  const [adverts, setAdverts] = React.useState<IAdverts>({});
  const [params, setParams] = React.useState<IAdParams | null>(null);

  const key = formatAdParams(params);

  const keyRef = React.useRef('');

  const prevSiteIdRef = React.useRef('');

  React.useEffect(() => {
    keyRef.current = key;
    if (!key || !params) {
      return;
    }

    /* eslint-disable no-useless-computed-key */
    const queryParameters = {
      ['tag[site]']: params.site,
      ['tag[section]']: 'sportnet',
      ['tag[default]']: 'sportnet',
      ['tag[article_template_type]']: params.articleTemplateType,
    };
    /* eslint-enable no-useless-computed-key */

    (async () => {
      try {
        const query = objectToParams(queryParameters as any);
        const urlWithParams = `${
          process.env.REACT_APP_PETITPRESS_API_URL || ''
        }/adverts/?${query}&env=${getAdvertsEnv()}`;

        const { current: prevSiteId = '' } = prevSiteIdRef;
        const { siteId = '' } = params;

        // console.log({ key, siteId,  prevKey, urlWithParams });

        if (siteId !== prevSiteId) {
          const response = await fetch(urlWithParams);

          if (!response.ok) {
            const err = new Error(response.statusText);
            try {
              (err as any).details = await response.json();
            } catch (e: any) {
              throw e;
            }
            throw err;
          }

          let newAdverts: IAdverts = await response.json();

          if (debug.adInit) {
            newAdverts = extendAdvertsWithDebuggingInfo(newAdverts, params);
          }

          // prevent race condition (naive solution)
          if (key === keyRef.current) {
            setTargetingParams(params);
            setAdverts(newAdverts);
          } else {
            if (debug.adInit) {
              console.log('Adverts: params changed before displaying results', {
                key,
                keyRef,
              });
            }
          }
        }
        prevSiteIdRef.current = siteId;
      } catch (e: any) {
        console.error('Adverts: error getting adverts:', e);
      }
    })();
  }, [key]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetParams = React.useCallback((newParams: IAdParams | null) => {
    setParams(newParams);
    // ads should always represent most recent params, if new params are set we loading adverts... we also need to remove old adverts
    setAdverts({});
    resetTargetingParams();
  }, []);

  const resetAdverts = React.useCallback(() => {
    setAdverts({});
  }, []);

  return (
    <AdContext.Provider
      value={{
        adverts,
        params,
        setParams: handleSetParams,
        resetAdverts,
      }}
    >
      {children}
    </AdContext.Provider>
  );
};

export default AdProvider;
