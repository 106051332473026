import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import {
  commit,
  CommitError,
  getListParameters,
} from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory, { thunkToAction } from 'typescript-fsa-redux-thunk';
import { OrganizationProfile } from '../../api/CoreApi';
import config from '../../config';
import {
  CustomThunkAction,
  ExtraArgumentType,
  RootState,
} from '../../configureStore';
import InternalServerError from '../../InternalServerError';
import { NormalizedEntities, Pager } from '../../library/App';
import {
  ICompetition,
  ICompetitionPart,
  IMatch,
} from '../../library/Competitions';
import NotFoundError from '../../NotFoundError';
import isObjectId from '../../utilities/isObjectId';
import normalizeEntities from '../../utilities/normalizeEntities';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import {
  currentCompetitionIdSelector,
  currentCompetitionPartIdSelector,
  currentCompetitionPartSelector,
  currentCompetitionSelector,
  getCurrentCompetitionAppSpaceSelector,
  getCurrentCompetitionIdSelector,
  getCurrentCompetitionPartIdSelector,
  getCurrentRoundIdSelector,
} from './selectors';

const create = actionCreatorFactory('COMPETITION');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

declare type OrganizationProfileWithTitle = OrganizationProfile & {
  title: string;
};

export const normalizeCompetitions = (
  data: ICompetition[],
  idKey: '_id' | '__issfId' = '_id'
) => {
  return {
    entities: {
      competitions: data.reduce((acc, item) => {
        return { ...acc, [String(item[idKey])]: item };
      }, {}) as { [key: string]: ICompetition },
    },
    result: data.map((item) => item[idKey]),
  };
};

export const normalizeCompetitionParts = (
  data: ICompetitionPart[],
  idKey: '_id' | '__issfId' = '_id'
) => {
  return {
    entities: {
      competitionParts: data.reduce((acc, item) => {
        return { ...acc, [item.__issfId || item._id]: item };
      }, {}) as { [key: string]: ICompetitionPart },
    },
    result: data.map((item) => item.__issfId || item._id),
  };
};

export const normalizeOrganizationProfiles = (
  data: OrganizationProfileWithTitle[]
) => {
  return {
    entities: {
      organizationProfiles: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: OrganizationProfileWithTitle },
    },
    result: data.map((item) => item._id),
  };
};

export const setCurrentAppSpace = create<string>('SET_CURRENT_APPSPACE');

export const setCurrentCompetitionId = create<string>(
  'SET_CURRENT_COMPETITION_ID'
);

export const setCurrentCompetitionGroupId = create<string>(
  'SET_CURRENT_COMPETITION_GROUP_ID'
);

export const setCurrentCompetitionPartId = create<string>(
  'SET_CURRENT_COMPETITION_PART_ID'
);

export const setCurrentRoundId = create<string>('SET_CURRENT_ROUND_ID');

export const loadCompetitionsByCompetitionGroup = createAsync<
  {
    appSpace: string;
    competitionGroupId: string;
    activeCompetitionId: string;
  },
  void
>(
  'LOAD_COMPETITIONS_BY_COMPETITION_GROUP',
  async (
    { appSpace, competitionGroupId, activeCompetitionId },
    dispatch,
    getState,
    { CompetitionsApi }
  ) => {
    return dispatch(
      commit.action({
        listName: config.LIST_COMPETITIONS_BY_GROUP_ID,
        load: async () => {
          if (isObjectId(competitionGroupId)) {
            let items: ICompetition[] = [];
            try {
              const competitionGroup =
                await CompetitionsApi.getCompetitionGroupById(
                  appSpace,
                  competitionGroupId
                );
              if (competitionGroup) {
                items = competitionGroup.competitions || [];
              }
            } catch (e: any) {
              const comp = await CompetitionsApi.getCompetitionById(
                appSpace,
                competitionGroupId
              );
              items = [comp];
            }
            if (!activeCompetitionId) {
              const recentCompetition = (items || []).find(
                (i: any) =>
                  (isBefore(new Date(i.season.dateFrom), new Date()) &&
                    isAfter(new Date(i.season.dateTo), new Date())) ||
                  isBefore(new Date(), new Date(i.season.dateFrom))
              );

              if (recentCompetition) {
                await dispatch(setCurrentCompetitionId(recentCompetition._id));
              } else {
                await dispatch(setCurrentCompetitionId(items[0]._id));
              }
            } else {
              await dispatch(setCurrentCompetitionId(activeCompetitionId));
            }
            const {
              entities: { competitions: competitionsEntities = {} } = {
                competitions: {},
              },
            } = await dispatch(loadCompetitionItem.action());
            const competition = competitionsEntities[
              Object.keys(competitionsEntities)[0]
            ] as any;

            if (competition) {
              const { competitions } =
                await CompetitionsApi.getCompetitionGroupById(
                  appSpace,
                  competition.competitionGroupId
                );
              const currCompetitionIdx = competitions.findIndex(
                (i) => i._id === competition._id
              );
              competitions[currCompetitionIdx] = competition;
              const { result, entities } = normalizeCompetitions(competitions);

              await dispatch(updateEntities(entities));

              return {
                results: result,
                total: competitions.length,
              };
            }
            return {
              results: [],
              total: 0,
            };
          } else {
            await dispatch(setCurrentCompetitionId(competitionGroupId));
            const {
              entities: { competitions: competitionsEntities = {} } = {
                competitions: {},
              },
            } = await dispatch(loadCompetitionItem.action());
            const competition = competitionsEntities[
              Object.keys(competitionsEntities)[0]
            ] as any;

            if (competition) {
              let { competitions } =
                await CompetitionsApi.getCompetitionGroupById(
                  appSpace,
                  competition.competitionGroupId
                );
              competitions = competitions.map((i) => {
                if (
                  i._id === competition._id ||
                  (i.__issfId &&
                    competition.__issfId &&
                    i.__issfId === competition.__issfId)
                ) {
                  return competition;
                }
                return i;
              });

              const { result, entities } = normalizeCompetitions(
                competitions,
                '__issfId'
              );

              await dispatch(updateEntities(entities));

              return {
                results: result,
                total: competitions.length,
              };
            }
            return {
              results: [],
              total: 0,
            };
          }
        },
      })
    );
  }
);

export const loadCompetition = createAsync<
  { appSpace: string; competitionId: string },
  NormalizedEntities<'competitions'>
>(
  'LOAD_COMPETITION',
  async (
    { appSpace, competitionId },
    dispatch,
    getState,
    { CompetitionsApi }
  ) => {
    try {
      const competition = await CompetitionsApi.getCompetitionById(
        appSpace,
        competitionId
      );

      const { entities } = normalizeCompetitions([competition]);

      dispatch(updateEntities(entities));
      return normalizeEntities('competitions', [competition]);
    } catch (e: any) {
      if (e && e.details && e.details.status === 404) {
        throw new NotFoundError(e);
      }
      throw new InternalServerError(e);
    }
  }
);

export const loadCompetitionItem = createAsync<
  void,
  NormalizedEntities<'competitions'>
>(
  'LOAD_COMPETITION_ITEM',
  async (parameters, dispatch, getState, { CompetitionsApi }) => {
    const currentCompetitionAppSpace = getCurrentCompetitionAppSpaceSelector(
      getState()
    );
    const currentCompetitionId = getCurrentCompetitionIdSelector(getState());

    return dispatch(
      loadCompetition.action({
        competitionId: currentCompetitionId,
        appSpace: currentCompetitionAppSpace,
      })
    );
  }
);

export const loadCompetitionTeams = createAsync<
  void,
  NormalizedEntities<'competitions'>
>(
  'LOAD_COMPETITION_TEAMS',
  async (params, dispatch, getState, { CompetitionsApi }) => {
    try {
      let competition = currentCompetitionSelector(getState());
      const currentCompetitionId = currentCompetitionIdSelector(getState());

      if (competition) {
        if (!competition.teams) {
          const { teams } = await CompetitionsApi.getCompetitionTeams(
            currentCompetitionId
          );
          competition = {
            ...competition,
            teams,
          };
        }
        const { entities } = normalizeCompetitions([competition], '__issfId');

        dispatch(updateEntities(entities));
        return normalizeEntities('competitions', [competition]);
      }
      return normalizeEntities('competitions', []);
    } catch (e: any) {
      if (e && e.details && e.details.status === 404) {
        throw new NotFoundError(e);
      }
      throw new InternalServerError(e);
    }
  }
);

export const loadCompetitionPart = createAsync<
  {
    appSpace: string;
    competitionId: string;
    competitionPartId: string;
    roundId?: string;
    omitResultsTable?: boolean;
  },
  NormalizedEntities<'competitionParts'>
>(
  'LOAD_COMPETITION_PART',
  async (
    { appSpace, competitionId, competitionPartId, roundId, omitResultsTable },
    dispatch,
    getState,
    { CompetitionsApi }
  ) => {
    try {
      const parameters: { roundId?: string; omitResultsTable?: boolean } = {};
      if (roundId) {
        parameters.roundId = roundId;
      }
      if (omitResultsTable) {
        parameters.omitResultsTable = omitResultsTable;
      }
      const part = (await CompetitionsApi.getCompetitionPartById(
        appSpace,
        competitionId,
        competitionPartId,
        parameters
      )) as any;

      const { entities } = normalizeCompetitionParts(
        [part],
        isObjectId(competitionPartId) ? '_id' : '__issfId'
      );

      dispatch(updateEntities(entities));
      return normalizeEntities('competitionParts', [part]);
    } catch (e: any) {
      if (e && e.details && e.details.status === 404) {
        throw new NotFoundError(e);
      }
      throw new InternalServerError(e);
    }
  }
);

export const loadCompetitionPartResults = createAsync<
  {
    competitionId?: string;
    competitionPartId?: string;
    roundId?: string;
  },
  NormalizedEntities<'competitionParts'>
>(
  'LOAD_COMPETITION_PART_RESULTS',
  async (
    { competitionId, competitionPartId, roundId },
    dispatch,
    getState,
    { CompetitionsApi }
  ) => {
    try {
      const parameters: { roundId?: string } = {};
      if (roundId) {
        parameters.roundId = roundId;
      }

      let part = currentCompetitionPartSelector(getState());
      const currentCompetitionId = currentCompetitionIdSelector(getState());
      const currentCompetitionPartId = currentCompetitionPartIdSelector(
        getState()
      );

      if (part) {
        const { results } =
          (await CompetitionsApi.getPublicCompetitionPartCurrentResultTable(
            currentCompetitionId || competitionId,
            currentCompetitionPartId || competitionPartId,
            {
              view: 'all',
            }
          )) as unknown as {
            results: Required<ICompetitionPart>['resultsTable']['results'];
          };
        const { teams } = await CompetitionsApi.getCompetitionPartTeams(
          currentCompetitionId || competitionId,
          currentCompetitionPartId || competitionPartId
        );
        part = {
          ...part,
          teams,
          resultsTable: {
            ...(part.resultsTable || {}),
            results,
          },
        };

        const { entities } = normalizeCompetitionParts(
          [part],
          isObjectId(competitionPartId || currentCompetitionPartId)
            ? '_id'
            : '__issfId'
        );

        dispatch(updateEntities(entities));
        return normalizeEntities('competitionParts', [part]);
      }
      return normalizeEntities('competitionParts', []);
    } catch (e: any) {
      if (e && e.details && e.details.status === 404) {
        throw new NotFoundError(e);
      }
      throw new InternalServerError(e);
    }
  }
);

export const loadCompetitionPartPlayers = createAsync<
  {
    competitionId: string;
    competitionPartId: string;
    roundId?: string;
    summary?: boolean;
  },
  NormalizedEntities<'competitionParts'>
>(
  'LOAD_COMPETITION_PART_PLAYERS',
  async (
    { competitionId, competitionPartId, roundId, summary },
    dispatch,
    getState,
    { CompetitionsApi }
  ): Promise<any> => {
    // cast na any kvoli circular ref RootState
    try {
      const parameters: { roundId?: string } = {};
      if (roundId) {
        parameters.roundId = roundId;
      }

      let part = currentCompetitionPartSelector(getState());

      if (part) {
        const currentCompetitionId = currentCompetitionIdSelector(getState());
        const currentCompetitionPartId = currentCompetitionPartIdSelector(
          getState()
        );

        let players: any = [];

        if (summary) {
          const result = await CompetitionsApi.getPublicCompetitionPlayersStats(
            competitionId || currentCompetitionId
          );
          players = result.players;
        } else {
          const result =
            await CompetitionsApi.getPublicCompetitionPartCurrentPlayersStats(
              competitionId || currentCompetitionId,
              competitionPartId || currentCompetitionPartId
            );
          players = result.players;
        }
        const { teams } = await CompetitionsApi.getCompetitionPartTeams(
          currentCompetitionId || competitionId,
          currentCompetitionPartId || competitionPartId
        );
        part = {
          ...part,
          teams,
          resultsTable: {
            ...(part.resultsTable || {}),
            players,
            results: part.resultsTable?.results || [],
          },
        };
        const { entities } = normalizeCompetitionParts(
          [part],
          isObjectId(competitionPartId || currentCompetitionPartId)
            ? '_id'
            : '__issfId'
        );

        dispatch(updateEntities(entities));
        return normalizeEntities('competitionParts', [part]);
      }
      return normalizeEntities('competitionParts', []);
    } catch (e: any) {
      if (e && e.details && e.details.status === 404) {
        throw new NotFoundError(e);
      }
      throw new InternalServerError(e);
    }
  }
);

export const loadCompetitionPartItem = createAsync<
  {},
  NormalizedEntities<'competitionParts'> | null | undefined
>(
  'LOAD_COMPETITION_PART_ITEM',
  async (parameters: any, dispatch, getState, { CompetitionsApi }) => {
    const currentCompetitionAppSpace = getCurrentCompetitionAppSpaceSelector(
      getState()
    );
    const currentCompetitionId = getCurrentCompetitionIdSelector(getState());
    const currentCompetitionPartId = getCurrentCompetitionPartIdSelector(
      getState()
    );
    const currentRoundId = getCurrentRoundIdSelector(getState());

    const currentCompetitionPart = currentCompetitionPartSelector(getState());
    if (currentCompetitionPart) {
      return null;
    }

    return dispatch(
      loadCompetitionPart.action({
        competitionId: currentCompetitionId,
        competitionPartId: currentCompetitionPartId,
        roundId: currentRoundId,
        appSpace: currentCompetitionAppSpace,
      })
    );
  }
);

export const loadOrganizationProfile = createAsync<
  { appSpace: string; expanded?: boolean },
  NormalizedEntities<'organizationProfiles'>
>(
  'LOAD_ORGANIZATION_PROFILE',
  async ({ appSpace, expanded }, dispatch, getState, { CoreApi }) => {
    try {
      let profile: any = await CoreApi.organizationRelatedPPO(
        'futbalsfz.sk',
        appSpace
      );
      if (expanded) {
        const [invoiceProfile, contactInfo, theme] = await Promise.all([
          CoreApi.ppoInvoiceProfile(appSpace),
          CoreApi.organizationPPOContactInfo(appSpace),
          CoreApi.organizationPPOTheme(appSpace),
        ]);
        profile = { ...profile, invoiceProfile, contactInfo, theme };
      }

      // doplnime title, ktore pozostava z nazvu a skratky
      const shortName = (profile.shortNames || []).find(
        ({ org_profile_id }) => org_profile_id === 'futbalsfz.sk'
      );
      profile = {
        ...profile,
        title: shortName
          ? `${profile.name} (${shortName.shortname})`
          : profile.name,
        shortName: shortName ? shortName.shortname : '',
      };

      const { entities } = normalizeOrganizationProfiles([profile]);

      dispatch(updateEntities(entities));
      return normalizeEntities('organizationProfiles', [profile]);
    } catch (e: any) {
      if (e && e.details && e.details.status === 404) {
        throw new NotFoundError(e);
      }
      throw new InternalServerError(e);
    }
  }
);

export const loadOrganizationProfileItem = createAsync<
  { expanded?: boolean },
  NormalizedEntities<'organizationProfiles'>
>(
  'LOAD_ORGANIZATION_PROFILE_ITEM',
  async ({ expanded }, dispatch, getState) => {
    const currentCompetitionAppSpace = getCurrentCompetitionAppSpaceSelector(
      getState()
    );

    return dispatch(
      loadOrganizationProfile.action({
        appSpace: currentCompetitionAppSpace || '',
        expanded,
      })
    );
  }
);

export const normalizeMatches = (data: IMatch[]) => {
  return {
    entities: {
      matches: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: IMatch },
    },
    result: data.map((item) => item._id),
  };
};

export const loadCompetitionMatches = createAsync<
  {
    listName: string;
    offset: number;
    limit?: number;
    dateBefore?: Date;
    dateAfter?: Date;
    competitionId?: string;
    appSpace?: string;
    closed?: boolean;
  },
  void
>(
  'LOAD_COMPETITION_MATCHES',
  async (
    {
      listName,
      appSpace,
      competitionId,
      dateBefore,
      dateAfter,
      closed,
      offset,
      limit = 20,
    },
    dispatch,
    getState,
    { CompetitionsApi }
  ) => {
    const currentCompetition = currentCompetitionSelector(getState());
    // const currentCompetitionPart = currentCompetitionPartSelector(getState());
    const currentCompetitionId = competitionId || currentCompetition?._id;
    const currentAppSpace =
      appSpace || getCurrentCompetitionAppSpaceSelector(getState());

    if (currentCompetitionId && currentAppSpace) {
      await dispatch(
        initializeOrSetListParams({
          listName,
          params: {
            competitionId: currentCompetitionId,
            offset,
          },
        })
      );
      return dispatch(
        commit.action({
          listName,
          load: async () => {
            const f: {
              dateBefore?: string;
              dateAfter?: string;
              limit: number;
              offset: number;
              closed?: boolean;
              withTeams?: boolean;
            } = {
              offset,
              limit,
            };
            if (typeof closed !== 'undefined') {
              f.closed = closed;
            }
            if (dateBefore) {
              f.dateBefore = dateBefore.toJSON();
            }
            if (dateAfter) {
              f.dateAfter = dateAfter.toJSON();
            }
            //if (currentCompetitionPart?.format === 'draw') {
            if (
              !(config.PAVUK_COMPETITION_IDS as unknown as string[]).includes(
                currentCompetition!._id
              )
            ) {
              f.withTeams = true;
            }
            const response = await CompetitionsApi.getCompetitionMatches(
              currentAppSpace,
              currentCompetitionId,
              f
            );

            const { result, entities } = normalizeMatches(response.matches);

            dispatch(updateEntities(entities));

            return {
              results: result,
              total: response.total,
              nextOffset: response.nextOffset,
              offset: response.offset,
            };
          },
        })
      );
    }
  }
);

export const loadCompetitionPartMatches = createAsync<
  {
    listName: string;
    offset: number;
    limit?: number;
    dateBefore?: Date;
    dateAfter?: Date;
    closed?: boolean;
  },
  void
>(
  'LOAD_COMPETITION_PART_MATCHES',
  async (
    { listName, dateBefore, dateAfter, offset, closed, limit = 20 },
    dispatch,
    getState,
    { CompetitionsApi }
  ) => {
    const currentCompetition = currentCompetitionSelector(getState());
    // const currentCompetitionPart = currentCompetitionPartSelector(getState());
    const currentCompetitionPartId = getCurrentCompetitionPartIdSelector(
      getState()
    );
    const appSpace = getCurrentCompetitionAppSpaceSelector(getState());

    if (currentCompetitionPartId) {
      await dispatch(
        initializeOrSetListParams({
          listName,
          params: {
            offset,
            competitionId: currentCompetition!._id,
            partId: currentCompetitionPartId,
          },
        })
      );
      return dispatch(
        commit.action({
          listName,
          load: async () => {
            const f: {
              dateBefore?: string;
              dateAfter?: string;
              closed?: boolean;
              limit: number;
              offset: number;
              withTeams?: boolean;
            } = {
              limit,
              offset,
            };
            if (dateBefore) {
              f.dateBefore = dateBefore.toJSON();
            }
            if (dateAfter) {
              f.dateAfter = dateAfter.toJSON();
            }
            if (typeof closed !== 'undefined') {
              f.closed = closed;
            }
            // if (currentCompetitionPart?.format === 'draw') {
            if (
              !(config.PAVUK_COMPETITION_IDS as unknown as string[]).includes(
                currentCompetition!._id
              )
            ) {
              f.withTeams = true;
            }
            let matches: any[] = [];
            let total = 0;
            let nextOffset: null | number = null;

            const response = await CompetitionsApi.getCompetitionPartMatches(
              appSpace,
              currentCompetition!._id,
              currentCompetitionPartId,
              { ...f, withDate: true }
            );
            matches = response.matches;
            total = response.total;
            nextOffset = response.nextOffset || null;

            if (!nextOffset) {
              const matchesWithoutDateResponse =
                await CompetitionsApi.getCompetitionPartMatches(
                  appSpace,
                  currentCompetition!._id,
                  currentCompetitionPartId,
                  {
                    ...f,
                    offset: 0,
                    limit: 32,
                    withDate: false,
                  }
                );
              matches = [...matches, ...matchesWithoutDateResponse.matches];
              nextOffset = null;
            }

            const { result, entities } = normalizeMatches(matches);

            dispatch(updateEntities(entities));

            return {
              results: result,
              total,
              nextOffset,
              offset: response.offset,
            };
          },
        })
      );
    }
  }
);

export const loadCompetitionParts = createAsync<
  {
    listName: string;
    activeCompetitionPartId?: string;
    withResults?: boolean;
    withPlayers?: boolean;
  },
  void
>(
  'LOAD_COMPETITION_PARTS',
  async (
    {
      listName,
      activeCompetitionPartId,
      withResults = false,
      withPlayers = false,
    },
    dispatch,
    getState,
    { CompetitionsApi }
  ) => {
    const appSpace = getCurrentCompetitionAppSpaceSelector(getState());
    const currentCompetitionId = getCurrentCompetitionIdSelector(getState());
    const currentCompetition = currentCompetitionSelector(getState());
    const params = {
      appSpace,
      competitionId: currentCompetitionId,
    };

    await dispatch(
      initializeOrSetListParams({
        listName,
        params,
      })
    );
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          // const { parts } = await CompetitionsApi.getCompetitionParts(
          //   params.appSpace,
          //   params.competitionId,
          // );

          const { result, entities } = normalizeCompetitionParts(
            currentCompetition?.parts || []
          );

          dispatch(updateEntities(entities));

          if (!activeCompetitionPartId && currentCompetition) {
            const recentCompetitionPart =
              (currentCompetition?.parts || []).find((part) => {
                return (
                  isBefore(new Date(part.dateTo || ''), new Date()) &&
                  isAfter(new Date(part.dateFrom || ''), new Date())
                );
              }) || (currentCompetition?.parts || []).length
                ? (currentCompetition?.parts || [])[0]
                : undefined;

            if (recentCompetitionPart) {
              const recentCompetitionPartId =
                recentCompetitionPart.__issfId || recentCompetitionPart._id;

              dispatch(
                setCurrentCompetitionPartId(String(recentCompetitionPartId))
              );
              const currentCompetitionPart = currentCompetitionPartSelector(
                getState()
              );

              if (!currentCompetitionPart) {
                await dispatch(
                  loadCompetitionPart({
                    appSpace,
                    competitionId: currentCompetitionId,
                    competitionPartId: String(recentCompetitionPartId),
                  })
                );
              }
              if (
                withResults &&
                (!currentCompetitionPart ||
                  !currentCompetitionPart.resultsTable?.results)
              ) {
                await dispatch(
                  thunkToAction(loadCompetitionPartResults)({
                    competitionId: currentCompetitionId,
                    competitionPartId: String(recentCompetitionPartId),
                  }) as any
                );
              }
              if (
                withPlayers &&
                (!currentCompetitionPart ||
                  !currentCompetitionPart.resultsTable?.players)
              ) {
                await dispatch(
                  loadCompetitionPartPlayers.action({
                    competitionId: currentCompetitionId,
                    competitionPartId: String(recentCompetitionPartId),
                  })
                );
              }
            }
          } else if (activeCompetitionPartId) {
            dispatch(setCurrentCompetitionPartId(activeCompetitionPartId));
            const currentCompetitionPart = currentCompetitionPartSelector(
              getState()
            );
            if (!currentCompetitionPart) {
              await dispatch(
                loadCompetitionPart({
                  appSpace,
                  competitionId: currentCompetitionId,
                  competitionPartId: activeCompetitionPartId,
                })
              );
            }
            if (
              withResults &&
              (!currentCompetitionPart ||
                !currentCompetitionPart.resultsTable?.results)
            ) {
              await dispatch(
                thunkToAction(loadCompetitionPartResults)({
                  competitionId: currentCompetitionId,
                  competitionPartId: activeCompetitionPartId,
                }) as any
              );
            }
            if (
              withPlayers &&
              (!currentCompetitionPart ||
                !currentCompetitionPart.resultsTable?.players)
            ) {
              await dispatch(
                loadCompetitionPartPlayers.action({
                  competitionId: currentCompetitionId,
                  competitionPartId: activeCompetitionPartId,
                })
              );
            }
          }

          return {
            results: result,
            total: 0,
          };
        },
      })
    );
  }
);

export const loadCompetitionMatchesBase = createAsync<
  {
    offset: number;
    limit?: number;
    dateBefore?: string;
    dateAfter?: string;
    competitionId?: string;
    appSpace?: string;
    closed?: boolean;
  },
  { results: string[] } & Pager
>(
  'LOAD_COMPETITION_MATCHES_BASE',
  async (
    {
      appSpace,
      competitionId,
      dateBefore,
      dateAfter,
      closed,
      offset,
      limit = 20,
    },
    dispatch,
    getState,
    { CompetitionsApi }
  ) => {
    const currentCompetition = currentCompetitionSelector(getState());
    const currentCompetitionId = competitionId || currentCompetition?._id || '';
    const currentAppSpace =
      appSpace || getCurrentCompetitionAppSpaceSelector(getState());

    const f: {
      dateBefore?: string;
      dateAfter?: string;
      limit: number;
      offset: number;
      closed?: boolean;
    } = {
      offset,
      limit,
    };
    if (typeof closed !== 'undefined') {
      f.closed = closed;
    }
    if (dateBefore) {
      f.dateBefore = dateBefore;
    }
    if (dateAfter) {
      f.dateAfter = dateAfter;
    }
    const response = await CompetitionsApi.getCompetitionMatches(
      currentAppSpace,
      currentCompetitionId,
      f
    );

    const { results, entities } = normalizeEntities(
      'matches',
      response.matches
    );

    dispatch(updateEntities(entities));

    return {
      results: results as string[],
      total: response.total,
      nextOffset: response.nextOffset,
      offset: response.offset,
      limit: response.limit,
    };
  }
);

export const loadPanelMatchesList = (
  listName: string
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { results: allMatches } = await dispatch(
              loadCompetitionMatchesBase.action({
                ...parameters,
                offset: 0,
                limit: 200,
              })
            );

            return {
              total: allMatches.length,
              results: parameters.dateBefore
                ? [...allMatches].reverse()
                : [...allMatches],
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      })
    );
  };
};
