import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { __ } from '@sportnet/ui/lib/utilities';
import { rem } from 'polished';
import useQuery, { StringParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import ItemsDropDown from '../../../components/DropDowns/ItemsDropDown';
import Icon from '../../../components/Icon';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import Loading from '../../../components/Loading';
import Spacer from '../../../components/Spacer';
import config from '../../../config';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import { RouteProps } from '../../../library/App';
import { ICompetition, ICompetitionPart } from '../../../library/Competitions';
import styled from '../../../theme/styled-components';
import getFutbalnetTeamName from '../../../utilities/futbalnet/club/getFutbalnetTeamName';
import getColor from '../../../utilities/getColor';
import getTeamBaseUrl from '../../../utilities/getTeamBaseUrl';
import sortTeams from '../../../utilities/sortTeams';
import {
  loadCompetitionPart,
  loadCompetitionPartResults,
  setCurrentCompetitionPartId,
} from '../../Competition/actions';
import {
  currentCompetitionPartIsFetchingSelector,
  currentCompetitionPartSelector,
  currentOrganizationProfileSelector,
  getCurrentCompetitionAppSpaceSelector,
} from '../../Competition/selectors';
import Standings from '../../Competition/Tables/standings';
import { currentClubCompetitionsSelector } from '../selectors';
import PrintButton from '../../FutbalnetPrint/PrintButton';
import getClubStandingPrintUrl from '../../../utilities/print/getClubStandingPrintUrl';
import { PrintButtonWrapper } from '../../Competition/Tables';

const Label = styled.div`
  color: #aaaaaa;
  font-size: ${rem(12)};
`;

const Filters = styled.div`
  .teamDropdown .dropdown-body {
    max-width: none;
  }
  margin: -${rem(16)} -${rem(16)} ${rem(24)} -${rem(16)};
  display: flex;
  flex-wrap: wrap;
  ${mb('m')} {
    flex-wrap: nowrap;
    margin: 0 0 ${rem(32)} 0;
  }
  > div {
    display: flex;
    flex-direction: column;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;
    width: 100%;
    padding-left: ${rem(16)};
    ${Label} {
      display: none;
      ${mb('m')} {
        display: block;
      }
    }
    ${mb('m')} {
      width: auto;
      border: 0;
      padding-left: ${rem(56)};
    }
  }
  > div:first-child {
    width: 100%;
    border: 0 !important;
    padding-bottom: ${rem(8)};
    ${Label} {
      display: block !important;
    }
    button {
      font-size: ${rem(18)} !important;
    }
    ${mb('m')} {
      width: auto;
      padding: 0;
    }
  }
  align-items: center;
  button {
    color: #000;
    width: 100%;
    cursor: pointer;
    padding: ${rem(8)} 0;
    font-size: ${rem(12)};
    display: flex;
    justify-content: space-between;
    ${mb('m')} {
      border-top: 0;
      border-bottom: 0;
      font-size: ${rem(18)};
      justify-content: flex-start;
    }
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    font-weight: normal;
    font-family: ${({ theme }) => theme.primaryFont.family};
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const StyledPrintButton = styled(PrintButton)`
  margin: ${rem(8)} ${rem(8)} 0 auto;
  ${mb('s')} {
    margin: 0 0 0 auto;
  }
`;

const MoreIcon = styled(Icon)`
  margin: 0 ${rem(16)} 0 ${rem(16)};
`;

const mapStateToProps = (state: RootState) => ({
  competitions: currentClubCompetitionsSelector(state),
  part: currentCompetitionPartSelector(state),
  isFetching: currentCompetitionPartIsFetchingSelector(state) !== false,
  appSpace: getCurrentCompetitionAppSpaceSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
});

type Props = RouteProps<{ klub: string; tim?: string }> &
  ReturnType<typeof mapStateToProps> & { dispatch: CustomThunkDispatch };

const QUERY_HOC_CONFIG = {
  parameters: {
    sutaz: StringParam(''),
    partId: StringParam(''),
  },
};

const ClubTablesDetail: React.FC<Props> = ({
  competitions,
  part,
  appSpace,
  isFetching,
  location: { search, pathname },
  dispatch,
  router,
  params: { klub, tim },
  organizationProfile,
}) => {
  const [teamFilterOpened, setTeamFilterOpened] = React.useState(false);
  const [competitionFilterOpened, setCompetitionFilterOpened] =
    React.useState(false);
  const [competitionPartFilterOpened, setCompetitionPartFilterOpened] =
    React.useState(false);

  const { query } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    QUERY_HOC_CONFIG
  );

  const toggleTeamFilter = () => setTeamFilterOpened(!teamFilterOpened);

  useAsyncData(async () => {
    if (query.sutaz) {
      const competitionItem = competitions.find(
        (c: any) => String(c.__issfId) === query.sutaz || c._id === query.sutaz
      ) as any;

      if (competitionItem && (competitionItem.parts || []).length) {
        const partId = query.partId || competitionItem.parts![0]._id;
        dispatch(setCurrentCompetitionPartId(partId));
        await dispatch(
          thunkToAction(loadCompetitionPart)({
            appSpace: competitionItem.appSpace,
            competitionId: competitionItem._id,
            competitionPartId: partId,
            omitResultsTable: true,
          }) as any
        );
        await dispatch(
          thunkToAction(loadCompetitionPartResults)({
            competitionId: competitionItem._id,
            competitionPartId: partId,
          }) as any
        );
      }
    }
  }, [query, competitions.length, tim]);

  const toggleCompetitionFilter = () =>
    setCompetitionFilterOpened(!competitionFilterOpened);
  const toggleCompetitionPartFilter = () =>
    setCompetitionPartFilterOpened(!competitionPartFilterOpened);

  const textColor = getColor('#333');
  const activeBorderColor = getColor('#CF2528');

  let implicitSeasonName: string | null = null;
  if (tim) {
    competitions.some((c) => {
      const teamIds = (c.parts || []).reduce((pAcc, p) => {
        return [
          ...pAcc,
          ...(p.teams || []).map((t) => String(t.__issfId) || t._id),
        ];
      }, []);
      if (teamIds.includes(tim)) {
        implicitSeasonName = c.season?.name || null;
        return true;
      }
      return false;
    });
  }

  const competition = competitions.find(
    (i) => String(i.__issfId) === query.sutaz || i._id === query.sutaz
  );
  let competitionPart: any = null;
  if (competition && query.partId) {
    const item = (competition.parts || []).find((i) => i._id === query.partId);
    competitionPart = item || null;
  }

  const formattedCompetitions = competitions
    .filter((i) =>
      implicitSeasonName
        ? implicitSeasonName === i.season?.name
        : (config.ACTIVE_SEASON_NAMES as unknown as string[]).includes(
            i.season?.name || ''
          )
    )
    .reduce((acc, i) => {
      const teamIds = (i.parts || []).reduce((pAcc, p) => {
        return [...pAcc, ...(p.teams || []).map((t) => t.__issfId || t._id)];
      }, []);

      if (tim && (teamIds.includes(Number(tim)) || teamIds.includes(tim))) {
        return [
          ...acc,
          {
            label: i.name,
            url: {
              pathname,
              query: { ...query, sutaz: i._id, partId: '' },
            },
          },
        ];
      }
      return acc;
    }, []);

  if (
    query.sutaz &&
    isFetching &&
    (!part || !(part.resultsTable?.results || []).length)
  ) {
    return <Loading />;
  }

  const formattedTeams = competitions
    .filter((i) =>
      implicitSeasonName
        ? i.season?.name === implicitSeasonName
        : (config.ACTIVE_SEASON_NAMES as unknown as string[]).includes(
            i.season?.name || ''
          )
    )
    .reduce((acc, i) => {
      return [
        ...acc,
        ...(i.parts || []).reduce((pAcc, p) => {
          return [
            ...pAcc,
            ...(p.teams || []).map((t) => ({
              ...t,
              gender: p.rules?.gender,
              category: p.rules?.category,
            })),
          ];
        }, []),
      ];
    }, []);

  formattedTeams.forEach((i, idx) => {
    const teamOccurenceCount = formattedTeams.filter((t) => t._id === i._id);
    if (teamOccurenceCount.length > 1) {
      delete formattedTeams[idx];
    }
  });

  const team = formattedTeams.find(
    (t) => t && (t._id === tim || t.__issfId === Number(tim))
  );

  const teamsItems = formattedTeams
    .filter((i) => i)
    .sort(sortTeams)
    .map((t: any) => ({
      label: `${t.displayName}${t.gender === 'F' ? ` ${__('(Ženy)')}` : ''}`,
      url: {
        query: {},
        pathname: `${getTeamBaseUrl({
          klub,
          teamId: String(t.__issfId) || t._id,
        })}tabulky/`,
      },
    }));

  const clubTeam =
    part && (part.teams || []).find((t) => t.appSpace === appSpace);

  // breadcrumbs
  const teamId = team ? String(team.__issfId) || team._id : '';
  const baseUrl = getTeamBaseUrl({ klub, teamId });

  return (
    <>
      <TopAd />
      <BottomPaddedBigAd name="big_1" />
      <RempIntegration destroy />

      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <Spacer size={4}>
            <div />
            <Filters>
              <ItemsDropDown
                className="teamDropdown"
                items={[...teamsItems]}
                opened={teamFilterOpened}
                onClose={toggleTeamFilter}
                color={textColor}
                activeColor={activeBorderColor}
              >
                <div>
                  <Label>{__('Tím')}</Label>
                  <button
                    style={{ fontWeight: 600 }}
                    onClick={toggleTeamFilter}
                  >
                    <div>
                      {team ? (team as any).displayName : __('Vyberte tím')}
                    </div>
                    <MoreIcon name="arrow-down" size={24} />
                  </button>
                </div>
              </ItemsDropDown>
              {tim && (
                <ItemsDropDown
                  items={[...formattedCompetitions]}
                  opened={competitionFilterOpened}
                  onClose={toggleCompetitionFilter}
                  color={textColor}
                  activeColor={activeBorderColor}
                >
                  <div>
                    <Label>{__('Súťaž')}</Label>
                    <button
                      style={{ fontWeight: 600 }}
                      onClick={toggleCompetitionFilter}
                    >
                      <div>
                        {competition ? competition.name : __('Vyberte súťaž')}
                      </div>
                      <MoreIcon name="arrow-down" size={24} />
                    </button>
                  </div>
                </ItemsDropDown>
              )}
              {!!competition && (competition.parts || []).length > 1 && (
                <ItemsDropDown
                  items={[
                    {
                      label: __('Aktuálna'),
                      url: { pathname, query: { ...query, partId: '' } },
                    },
                    ...(competition.parts || []).map((i) => ({
                      label: i.name,
                      url: { pathname, query: { ...query, partId: i._id } },
                    })),
                  ]}
                  opened={competitionPartFilterOpened}
                  onClose={toggleCompetitionPartFilter}
                  color={textColor}
                  activeColor={activeBorderColor}
                >
                  <div>
                    <Label>{__('Časť súťaže')}</Label>
                    <button onClick={toggleCompetitionPartFilter}>
                      <div>
                        {!!competitionPart
                          ? (competitionPart as ICompetitionPart).name
                          : __('Aktuálna')}
                      </div>
                      <MoreIcon name="arrow-down" size={24} />
                    </button>
                  </div>
                </ItemsDropDown>
              )}
            </Filters>
            {!!competition && !!part && (
              <>
                <Spacer size={3}>
                  {part && part.resultsTable?.results && (
                    <Standings
                      highligtedTeamIds={clubTeam ? [clubTeam._id] : []}
                      currentCompetitionPart={part}
                      zvaz={competition.appSpace}
                      currentCompetition={competition as ICompetition}
                    />
                  )}
                  <PrintButtonWrapper>
                    <PrintButton
                      href={getClubStandingPrintUrl(
                        klub,
                        competition.appSpace,
                        competition.__issfId || competition._id,
                        part._id,
                        'stats',
                        teamId
                      )}
                      gtmEventLabel="tabulka"
                    />
                  </PrintButtonWrapper>
                </Spacer>
              </>
            )}
          </Spacer>
        </SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      <MegaboardAd />
      {organizationProfile?.name && team && (
        <FutbalnetUnionBreadcrumbs
          crumbs={[
            {
              label: organizationProfile.name,
              url: baseUrl,
            },
            {
              label: `Tabuľky ${getFutbalnetTeamName(team)}`,
              url: `${baseUrl}tabulky/${search || ''}`,
            },
          ]}
        />
      )}
    </>
  );
};

export default compose(
  connect(mapStateToProps),
  withRouter
)(ClubTablesDetail) as any;
