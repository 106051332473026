import useQuery, {
  StringParam,
  serializeQuery,
} from '@sportnet/query-hoc/useQuery';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useQ } from '../../../query';
import loadCompetitionPartWithResultsTable from './features/loadCompetitionPartWithResultsTable';
import {
  CompetitionNameLoader,
  Footer,
  H1,
  Header,
  PageBreak,
  Wrapper,
} from '../CompetitionMatchesPrint';
import { SKELETON_CLASS_NAME } from '../../../components/v2/SkeletonLoader';
import RenderPrintDataLoader from '../CompetitionMatchesPrint/RenderPrintDataLoader';
import __ from '../../../utilities/__';
import FooterLogos from '../CompetitionMatchesPrint/FooterLogos';
import RenderCompetitionStandingPrintData from './RenderCompetitionStandingPrintData';
import { IBaseObjectName } from '../../../library/CompetitionStanding';
import { InfoMessage } from '../CompetitionMatchesPrint/RenderPrintData';
import getAppSpaceFromUrl from '../../../utilities/getAppSpaceFromUrl';
import getBaseUrl from '../../../utilities/getBaseUrl';
import FutbalnetPrintQrCode from '../FutbalnetPrintQrCode';
import removeUndefinedQueryParams from '../../../utilities/urls/removeUndefinedQueryParams';
import { Helmet } from 'react-helmet-async';

type RouterProps = RouteComponentProps<
  {
    zvaz: string;
    sutaz: string;
    competitionPartId: string;
  },
  void
>;

const QUERY_HOC_CONFIG = {
  parameters: {
    tableView: StringParam(''),
    team: StringParam(''),
  },
};

const CompetitionStandingPrint: React.FC<RouterProps> = ({
  router,
  location: { search, pathname },
  params: { zvaz, sutaz: competitionId, competitionPartId },
}) => {
  const competitionAppSpace = getAppSpaceFromUrl('union', zvaz) || zvaz;

  const { query } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    QUERY_HOC_CONFIG
  );

  const tableView = (query.tableView || 'stats') as IBaseObjectName;

  const qrCodeUrlQuery = serializeQuery(
    removeUndefinedQueryParams({
      tableView: tableView !== 'stats' ? tableView : undefined,
      part: competitionPartId,
    })
  );

  const qrCodeUrl = `${getBaseUrl()}/futbalnet/z/${zvaz}/s/${competitionId}/tabulky/${
    qrCodeUrlQuery === '?' ? '' : qrCodeUrlQuery
  }`;

  // detail casti sutaze s vysledkovou tabulkou
  const {
    data: competitionPart,
    isError: isCompetitionPartError,
    isLoading: isCompetititonPartLoading,
  } = useQ(
    loadCompetitionPartWithResultsTable,
    { appSpace: competitionAppSpace, competitionId, competitionPartId },
    {
      enabled: !!competitionAppSpace && !!competitionId,
      refetchOnWindowFocus: false,
    }
  );

  const showPrintDialog = !isCompetititonPartLoading;

  React.useEffect(() => {
    if (showPrintDialog) {
      // nechajme 100ms pre render
      window.setTimeout(() => {
        window.print();
      }, 100);
    }
  }, [showPrintDialog]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex nofollow" />
      </Helmet>
      <Wrapper>
        <Header>
          {isCompetititonPartLoading ? (
            <CompetitionNameLoader className={SKELETON_CLASS_NAME} />
          ) : competitionPart ? (
            <>
              <H1>
                {competitionPart.competition.name} -{' '}
                {competitionPart.competition.season?.name ?? ''}
                {tableView === 'stats' ? null : tableView === 'awayStats' ? (
                  <>&nbsp;({__('Vonku')})</>
                ) : tableView === 'homeStats' ? (
                  <>&nbsp;({__('Doma')})</>
                ) : null}
              </H1>
            </>
          ) : null}
          <FutbalnetPrintQrCode url={qrCodeUrl} />
        </Header>
        {isCompetititonPartLoading ? (
          <RenderPrintDataLoader />
        ) : isCompetitionPartError ? (
          <InfoMessage
            text={`${__('Pri získavaní dát pre tlač sa vyskytla chyba!')}`}
          />
        ) : competitionPart ? (
          <>
            <RenderCompetitionStandingPrintData
              competitionStandingPrintData={competitionPart as any}
              tableView={tableView}
            />
          </>
        ) : null}
        <PageBreak />
        <Footer>
          <FooterLogos />
        </Footer>
      </Wrapper>
    </>
  );
};

export default CompetitionStandingPrint;
