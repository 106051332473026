/* tslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Sector = {
  _id: string;

  category: 'sport';

  itemId: string;

  sectorId: string;

  matricityPPO?: string;
};
export type PushToken = {
  tokenId: string;

  token: string;

  platform: 'ANDROID' | 'IOS' | 'WEB';

  type: 'DEBUG' | 'RELEASE';

  name?: string;

  badge?: number;
};
export type AppSpace = {
  _id: string;

  user_id: string;

  app_id: string;

  app_space: string;

  display_name?: string | null;

  role: string;

  child_apps_role?: Array<{
    app_id: string;

    role: string | null;
  }>;

  grant: boolean;

  validTo?: string | null;

  org_profile?: {
    _id?: string;

    name?: string;

    type?: string;

    sport?: string;

    status?: string;

    relations?: {
      related_ppo?: string;

      date_from?: string;

      date_to?: string | null;

      member_until?: string | null;

      status?: elementary_itemStatus;
    };

    parent_ppo?: string | null;

    organization_id?: string;

    organization_name?: string;

    logo_public_url?: string | null;

    favicon_public_url?: string | null;
  };
};
export type AppSpaceUser = {
  user_id: string;

  app_id: string;

  app_space: string;

  display_name?: string | null;

  role: string;

  child_apps_role?: Array<{
    app_id: string;

    role: string | null;
  }>;

  grant: boolean;

  validTo?: string | null;

  org_profile?: {
    _id?: string;

    name?: string;

    type?: string;

    sport?: string;

    status?: string;

    relations?: {
      related_ppo?: string;

      date_from?: string;

      date_to?: string | null;

      member_until?: string | null;

      status?: elementary_itemStatus;
    };

    parent_ppo?: string | null;

    organization_id?: string;

    organization_name?: string;

    logo_public_url?: string | null;

    favicon_public_url?: string | null;
  };
};
export type UserProfileUpdate_Admin = {
  username?: string;

  idnr?: string | null;

  name?: string;

  surname?: string;

  family_name?: string;

  titles?: {
    before: string;

    after: string;
  };

  phone?: string;

  email?: elementary_email;

  sex?: elementary_sex;

  birthdate?: string | null;

  deathdate?: string | null;

  birthplace?: string;

  birthcountry?: string;

  shoesize?: string;

  dresssize?: string;

  nationality?: string;

  citizenship?: string;

  membership_status?: string;

  import_id?: string;

  external_id?: string;
};
export type UserCreate_CRM = {
  sport_sector: string;

  user_profile: {
    username: string;

    birthdate: string;

    idnr?: string | null;

    name: string;

    surname: string;

    email: elementary_email;

    birthcountry: string;

    citizenship: string;

    nationality?: string;
  };
};
export type UserProfileUpdate = {
  name?: string;

  surname?: string;

  family_name?: string;

  titles?: {
    before: string;

    after: string;
  };

  phone?: string;

  email?: elementary_email;

  sex?: elementary_sex;

  birthdate?: string | null;

  birthplace?: string;

  birthcountry?: string;

  shoesize?: string;

  dresssize?: string;

  nationality?: string;

  citizenship?: string;

  photo?: {
    public?: boolean;
  };
};
export type UserProfileUpdate_Registrar = {
  name?: string;

  surname?: string;

  family_name?: string;

  titles?: {
    before: string;

    after: string;
  };

  phone?: string;

  email?: elementary_email;

  sex?: elementary_sex;

  birthdate?: string | null;

  deathdate?: string | null;

  idnr?: string;

  birthcountry?: string;

  nationality?: string;

  citizenship?: string;

  formalPhoto?: {
    public: boolean;
  };
};
export type UserExternalProfile = {
  external_id?: string;

  external_service?: string;

  user_info_update?: string;

  user_info?: {};
};
export type UserProfile = {
  _id: string;

  name?: string;

  surname?: string;

  family_name?: string;

  titles?: {
    before?: string;

    after?: string;
  };

  deleteAfter?: string;

  email?: string;

  phone?: string;

  sex?: elementary_sex;

  birthdate?: string | null;

  birthplace?: string;

  birthcountry?: string;

  deathdate?: string | null;

  shoesize?: string;

  dresssize?: string;

  nationality?: string;

  citizenship?: string;

  membership_status?: string;

  import_id?: string;

  external_id?: string;

  created_datetime?: string;

  email_verified?: string | null;

  username?: string;

  photo?: object | null;

  idnr?: string | null;

  ppoRelation?: UserPPORelation;

  formalPhoto?: UserFormalPhoto;
};
export type UserPPORelation = {
  ppo: string;

  flags?: Array<string>;
};
export type UserBasicProfile = {
  _id: string;

  name?: string;

  surname?: string;

  family_name?: string;

  titles?: {
    before?: string;

    after?: string;
  };

  deleteAfter?: string;
};
export type UserBasicProfileWithGroups = {
  _id: string;

  name?: string;

  surname?: string;

  family_name?: string;

  titles?: {
    before?: string;

    after?: string;
  };

  deleteAfter?: string;

  groups?: Array<{
    _id?: string;

    name?: string;
  }>;
};
export type UserTransfer = {
  _id?: string;

  dateFrom?: string;

  dateTo?: string;

  sourceClubPPO?: string;

  sourceClubName?: string;

  destinationClubPPO?: string;

  destinationClubName?: string;

  personSeasonAgeLevelU?: string;

  seasonName?: string;

  transferStatus?: string;

  transferType?: string;

  destinationCompetitionName?: string;

  destinationCompetitionPPO?: string;

  destinationCompetitionIssfID?: number;
};
export type UserSportOrg = {
  org_id?: string;

  org_name?: string;

  org_profile_id?: string;

  org_profile_name?: string;

  source_ppo?: string;

  source_ppo_name?: string;

  sector?: Sector;

  competence_type?: string;

  date_from?: string;

  date_to?: string | null;

  transfer_type?: string;

  valid_to?: string | null;
};
export type UserBasicProfileWithAffiliations = {
  _id: string;

  name?: string;

  surname?: string;

  family_name?: string;

  titles?: {
    before?: string;

    after?: string;
  };

  deleteAfter?: string;

  photo?: {
    token?: string;

    public?: boolean;

    public_url?: string;
  };

  membership?: {
    regnr?: string;
  };

  sport_orgs?: Array<{
    type?: string;

    org_id?: string;

    org_name?: string;

    org_profile_id?: string;

    org_profile_name?: string;

    source_ppo?: string;

    source_ppo_name?: string;

    sector?: Sector;

    competence_type?: string;

    date_from?: string;

    date_to?: string | null;

    transfer_type?: string;

    valid_to?: string | null;
  }>;

  sport_expert_orgs?: Array<UserSportExpertOrg>;

  regnrs?: Array<{}>;

  bio?: Array<{
    _id: string;

    org_id: string;

    org_name: string;

    org_profile_id: string;

    org_profile_name: string;

    photo?: MediaManagerPhoto;

    perex?: string;

    facebook?: string;

    twitter?: string;

    instagram?: string;

    youtube?: string;

    widgets?: Array<Widget>;
  }>;

  awards?: Array<{
    _id: elementary__id;

    org_profile_id?: string;

    sport?: string;

    sport_sector?: string;

    award_type?: string;

    issue_date?: string;

    issued_by?: string;

    created_datetime?: string;

    org_id?: string;
  }>;

  custom?: Array<{
    _id: elementary__id;

    org_profile_id: string;

    label: string;

    value: string;
  }>;

  formalPhoto?: UserFormalPhoto;
};
export type UserPublicProfile = {
  _id: string;

  name?: string;

  surname?: string;

  family_name?: string;

  titles?: {
    before?: string;

    after?: string;
  };

  deleteAfter?: string;

  email?: string;

  phone?: string;

  sex?: elementary_sex;

  birthdate?: string | null;

  birthplace?: string;

  birthcountry?: string;

  deathdate?: string | null;

  shoesize?: string;

  dresssize?: string;

  nationality?: string;

  citizenship?: string;

  membership_status?: string;

  import_id?: string;

  external_id?: string;

  created_datetime?: string;

  email_verified?: string | null;

  username?: string;

  photo?: object | null;
};
export type TokenInfo = {
  client_id?: string;

  token_type?: 'Bearer';

  expires_at?: number;

  expires_in?: number;

  expires_at_date?: string;

  user_id?: string;

  scopes?: Array<string>;

  userinfo?: UserPublicProfile;

  usergroups?: Array<{
    ppo?: string;

    group?: Array<string>;
  }>;

  appspace?: AppSpaceUser;

  org_info?: Organization;

  ppo_info?: OrganizationProfile;
};
export type elementary_sex = 'M' | 'F' | null;
export type elementary_email = string;
export type elementary_itemStatus = 'active' | 'inactive';
export type elementary__id = string;
export type Error = {
  statusCode?: number;

  name?: string;

  description?: string | null;

  payload?: object | null;

  userinfo?: string;
};
export type AddressCreateOrUpdate = {
  street: string;

  number: string | number;

  conscription_number?: string | number;

  city: string;

  zip: string;

  country: string;

  name?: string;

  surname?: string;

  company?: string;

  type: string;

  status: elementary_itemStatus;

  location?: GeoLocation;
};
export type Address = {
  street?: string;

  number?: string | number;

  conscription_number?: string | number;

  city?: string;

  zip?: string;

  country?: string;

  name?: string;

  surname?: string;

  company?: string;

  type?: string;

  location?: GeoLocation;
};
export type GeoLocation = object | null;
export type BankAccount = {
  IBAN?: string;

  type?: string;
};
export type BankAccountCreateOrUpdate = {
  IBAN: string;

  type: string;

  status: elementary_itemStatus;
};
export type CustomField = {
  org_profile_id: string;

  label: string;

  value: string;
};
export type Widget = {
  type: string;
};
export type CustomFieldCreateOrUpdate = {
  org_profile_id: string;

  label: string;

  value: string;
};
export type ItemStatus = {
  status?: elementary_itemStatus;
};
export type ItemId = {
  _id: elementary__id;
};
export type UserSportExpertOrg = {
  org_id?: string;

  org_profile_id?: string;

  org_name?: string;

  org_profile_name?: string;

  sector?: Sector;

  competence_type?: string;

  legal_form?: string;

  license_id?: string;

  license?: UserLicense;

  date_from?: string;

  date_to?: string | null;

  valid_to?: string | null;
};
export type FileObject = {
  _id?: elementary__id;

  name?: string;

  mimetype?: string;

  size?: number;

  filepath?: string;
};
export type UserLicense = {
  org_profile_id?: string;

  type?: string;

  name?: string;

  documentnr?: string;

  valid_from?: string;

  valid_to?: string | null;

  issued_by_po?: string;

  verified_by_person?: string;

  verified_by_position?: string;

  registration_date?: string;

  org_name?: string;

  org_profile_name?: string;

  note?: string;

  files?: Array<FileObject>;
};
export type IssuerLicense = {
  _id?: string;

  name?: string;

  type?: string;

  subTypeId?: number;
};
export type UserLicenseCreateOrUpdate = {
  org_profile_id: string;

  type: string;

  name: string;

  documentnr: string;

  valid_from: string;

  valid_to?: string | null;

  issued_by_po: string;

  verified_by_person: string;

  verified_by_position: string;

  registration_date: string;

  note?: string;
};
export type UserDocument = {
  org_profile_id?: string;

  name?: string;

  mimetype?: string;

  size?: number;

  filepath?: string;

  type?: string;

  created_date?: string;

  note?: string;
};
export type Pager = {
  total?: number;

  limit?: number;

  offset?: number;

  next_offset?: number | null;
};
export type UserSportOrgCreate = {
  sector: Sector;

  competence_type: string;

  org_id?: string;

  org_profile_id: string;

  transfer_type: string;

  date_from: string;

  date_to?: string | null;
};
export type UserSportOrgUpdate = {
  date_from: string | null;

  date_to?: string | null;

  transfer_type: string;

  competence_type: string;

  status?: elementary_itemStatus;
};
export type UserSportExpertOrgCreate = {
  sector: Sector;

  org_profile_id: string;

  competence_type: string;

  legal_form: string;

  license_id?: string | null;

  date_from: string;

  date_to?: string | null;
};
export type UserSportExpertOrgUpdate = {
  date_from: string | null;

  date_to?: string | null;

  legal_form: string;

  competence_type: string;

  license_id?: string | null;
};
export type App = {
  _id: string;

  secret: string;

  name: string;

  primary?: boolean;

  allowed_hosts: Array<string>;

  response_types?: Array<string>;

  description?: string;

  url?: string;

  marketingUrl?: string | null;

  appSpaceUrlPattern?: string;

  logo?: string;

  roles?: Array<AppRole>;

  parent_apps?: Array<string>;
};
export type AppPublic = {
  _id?: string;

  name?: string;

  description?: string | null;

  url?: string | null;

  marketingUrl?: string | null;

  appSpaceUrlPattern?: string | null;

  action_buttons?: Array<{
    label: string;

    urlPattern: string;

    roles?: Array<string>;
  }>;

  logo?: string | null;

  roles?: Array<AppRole>;

  flags?: Array<string>;

  child_apps?: Array<AppPublic_>;
};
export type AppPublic_ = AppPublic;
export type AppRole = {
  role: string;

  name?: string;
};
export type AppCreate = {
  _id: string;

  secret: string;

  name: string;

  allowed_hosts: Array<string>;

  response_types?: Array<string>;

  description?: string | null;

  url?: string | null;

  logo?: string | null;

  roles?: Array<AppRole>;

  parent_apps?: Array<string>;
};
export type AppUpdate = {
  secret?: string;

  name?: string;

  allowed_hosts?: Array<string>;

  response_types?: Array<string>;

  description?: string | null;

  url?: string | null;

  logo?: string | null;

  roles?: Array<AppRole>;

  parent_apps?: Array<string>;
};
export type Organization = {
  _id?: string;

  full_name?: string;

  name?: string;

  legal_form?: string;

  business_id?: string;

  vat_nr?: string | null;

  vat_foreign_trade_info?: string;

  vat_alternatives?: Array<string>;

  email?: string;

  www?: string;

  phone?: string;

  fax?: string;

  contact_person?: string;

  statutory?: string;

  logo_public_url?: string | null;

  favicon_public_url?: string | null;
};
export type OrganizationWithProfiles = {
  _id?: string;

  full_name?: string;

  name?: string;

  legal_form?: string;

  business_id?: string;

  vat_nr?: string | null;

  vat_foreign_trade_info?: string;

  vat_alternatives?: Array<string>;

  email?: string;

  www?: string;

  phone?: string;

  fax?: string;

  contact_person?: string;

  statutory?: string;

  logo_public_url?: string | null;

  favicon_public_url?: string | null;

  profiles?: Array<OrganizationProfile>;
};
export type OrganizationChangeRequest = {
  full_name?: string;

  name?: string;

  legal_form?: string;

  business_id?: string;

  vat_nr?: string | null;

  email?: string;

  www?: string;

  phone?: string;

  fax?: string;

  contact_person?: string;

  statutory?: string;

  bank_accounts?: Array<{
    _id: elementary__id;

    status?: elementary_itemStatus;

    IBAN?: string;

    type?: string;
  }>;

  addresses?: Array<{
    _id: elementary__id;

    status?: elementary_itemStatus;

    street?: string;

    number?: string | number;

    conscription_number?: string | number;

    city?: string;

    zip?: string;

    country?: string;

    name?: string;

    surname?: string;

    company?: string;

    type?: string;

    location?: GeoLocation;
  }>;

  custom?: Array<{
    _id: elementary__id;

    org_profile_id: string;

    label: string;

    value: string;
  }>;
};
export type OrganizationUpdate = {
  full_name?: string;

  name?: string;

  legal_form?: string;

  business_id?: string;

  vat_nr?: string | null;

  email?: string;

  www?: string;

  phone?: string;

  fax?: string;

  contact_person?: string;

  statutory?: string;
};
export type OrganizationCreate = {
  full_name: string;

  name?: string;

  legal_form: string;

  business_id: string;

  vat_nr?: string | null;

  email?: string;

  www?: string;

  phone?: string;

  fax?: string;

  contact_person?: string;

  statutory?: string;
};
export type OrganizationProfileStructureItem = {
  _id: string;

  name: string;

  logo_public_url?: string;

  subs?: Array<OrganizationProfileStructureItem>;
};
export type OrganizationShortName = {
  _id: string;

  shortname: string;

  org_profile_id: string;
};
export type OrganizationProfile = {
  _id?: string;

  name?: string;

  country?: string;

  type?: string;

  sectors?: Array<{
    category?: 'sport';

    itemId?: string;

    sectorId?: string;

    matricityPPO?: string;

    _id?: string;
  }>;

  sport?: string;

  status?: string;

  parent_relations?: Array<OrganizationProfile2>;

  matricity_relations?: Array<OrganizationProfile2>;

  organization_id?: string;

  organization_name?: string;

  logo_public_url?: string | null;

  favicon_public_url?: string | null;

  shortNames?: Array<OrganizationShortName>;
};
export type OrganizationProfile2 = OrganizationProfile;
export type OrganizationProfileRelation = {
  related_ppo?: string;

  date_from?: string;

  date_to?: string | null;

  member_until?: string | null;

  status?: elementary_itemStatus;

  related_ppo_name?: string;

  related_ppo_organization_name?: string;
};
export type OrganizationProfileRelationCreate = {
  related_ppo: string;

  date_from: string;

  date_to?: string | null;

  member_until?: string | null;

  status: elementary_itemStatus;
};
export type OrganizationProfileRelationUpdate = {
  date_from?: string | null;

  date_to?: string | null;

  member_until?: string | null;

  status?: string;
};
export type OrganizationProfileCreate = {
  _id: string;

  name: string;

  status?: string;

  type: string;

  sport: string | null;

  parent_ppo?: string | null;
};
export type OrganizationProfileUpdate = {
  name?: string;

  type?: string;

  status?: string;

  sport?: string | null;

  parent_ppo?: string | null;
};
export type Codelist = {
  codelist?: Array<{
    value: string;

    label?: string;

    default?: boolean;
  }>;
};
export type UserAward = {
  org_profile_id?: string;

  sport?: string;

  sport_sector?: string;

  award_type?: string;

  issue_date?: string;

  issued_by?: string;

  created_datetime?: string;

  org_id?: string;
};
export type UserAwardCreate = {
  org_profile_id: string;

  sport: string;

  sport_sector: string;

  award_type: string;

  issue_date: string;

  issued_by?: string;
};
export type UserAwardUpdate = {
  org_profile_id?: string;

  sport?: string;

  sport_sector?: string;

  award_type?: string;

  issue_date?: string;

  issued_by?: string;
};
export type UserMembership = {
  org_profile_id?: string;

  org_profile_name?: string;

  org_name?: string;

  valid_from?: string;

  valid_to?: string;

  status?: UserMembershipStatus;
};
export type UserMembershipStatus =
  | 'active'
  | 'inactive'
  | 'cancelled'
  | 'excluded';
export type UserMembershipCreate = {
  org_profile_id: string;

  status: UserMembershipStatus;

  valid_from: string;

  valid_to: string;
};
export type UserMembershipUpdate = {
  status?: UserMembershipStatus;

  valid_from?: string;

  valid_to?: string;
};
export type UserRegnr = {
  org_profile_id?: string;

  org_profile_name?: string;

  org_name?: string;

  regnr?: string;
};
export type UserRegnrCreate = {
  org_profile_id: string;

  regnr?: string;

  useSequence?: boolean;
};
export type UserRegnrUpdate = {
  regnr?: string;
};
export type UserFormalPhoto = {
  org_profile_id: string;

  token: string;

  public: boolean;

  public_url: string;
};
export type UserMedicalExamination = {
  org_profile_id: string;

  doctor?: string;

  valid_from?: string;

  valid_to?: string | null;
};
export type UserMedicalExaminationCreateOrUpdate = {
  org_profile_id: string;

  doctor: string;

  valid_from: string;

  valid_to?: string | null;
};
export type PpoRelation = {
  ppo?: string;

  org_profile_name?: string;

  org_id?: string;

  org_name?: string;

  flags?: Array<string>;
};
export type RequestCreate = {
  user?: {
    email: elementary_email;

    name?: string;

    surname?: string;

    phone?: string;
  };

  note?: string;
};
export type RequestCallbacks = Array<{
  url: string;

  payload?: {};
}>;
export type PoCreate = {
  organization?: OrganizationCreate;

  profile?: {
    name?: string;

    type?: string;
  };

  address?: Address;

  addresses?: Array<Address>;

  bank_account?: BankAccount;

  targetGroups?: Array<{
    targetGroupIdx?: number;

    months?: Array<string>;
  }>;

  users?: Array<{
    sportnetId: string;

    name: string;

    license_type: string;

    license_id: string;
  }>;
};
export type RequestCreate_PoRegistry = {
  user?: {
    email: elementary_email;

    name?: string;

    surname?: string;

    phone?: string;
  };

  note?: string;

  callbacks?: RequestCallbacks;

  data?: {
    po?: PoCreate;

    source?: string;
  };
};
export type Request = {
  _id: string;

  appSpace: string;

  request_type: string;

  confirmedBy?: Array<{}>;

  workflow?: {
    transitions?: {};
  };

  status:
    | 'REJECTED'
    | 'CANCELLED'
    | 'APPROVED'
    | 'NEW'
    | 'PARTIALLY_CONFIRMED'
    | 'CONFIRMED'
    | 'CLOSED';

  ownerUser?: {
    display_name: string;

    email: elementary_email;

    sportnet_id: string;
  };

  last_update?: string;

  created_date: string;

  status_log?: Array<{
    change_date?: string;

    old_status?: string;

    new_status?: string;

    admin_user?: {
      display_name?: string;

      sportnet_id?: string;
    };

    ip?: string | null;

    ips?: Array<string | null> | null;
  }>;

  paymentItem?: Array<PPOPricelistItem>;

  paygate?: Array<{
    ordernumber?: number;

    createdDate?: string;
  }>;

  payment?: {
    ordernumber?: number;

    paid_date?: string;
  };

  user?: {
    email: elementary_email;

    name?: string;

    surname?: string;

    phone?: string;

    sportnet_id?: string;
  };

  note?: string;

  user_note?: string;

  data?: {};
};
export type RequestListItem = {
  _id: string;

  request_type: string;

  status:
    | 'REJECTED'
    | 'CANCELLED'
    | 'APPROVED'
    | 'NEW'
    | 'PARTIALLY_CONFIRMED'
    | 'CONFIRMED'
    | 'CLOSED';

  ownerUser?: {
    display_name: string;

    email: elementary_email;

    sportnet_id: string;
  };

  created_date: string;

  data?: {};
};
export type InvoiceItem = {
  ppo?: string;

  invoicenr?: string;

  storno?: boolean;

  stornoDate?: string;

  stornoBy?: {
    sportnetId?: string;

    displayName?: string;
  };

  deliveryDate?: string;

  payDueDate?: string;

  issuedBy?: string;

  seller?: Organization;

  buyer?: {
    name?: string;

    surname?: string;

    invoice_address?: {
      address?: string;

      city?: string;

      zip?: string;
    };
  };

  items?: Array<{}>;
};
export type PPOPricelistItem = {
  _id?: string;

  type: 'membership' | 'application' | 'applicationPcn' | 'ppomembership';

  name: string;

  applicationId?: string;

  price?: Price;

  pcn?: number;

  description?: string;

  ageFrom?: number;

  ageTo?: number | null;

  sex?: elementary_sex;

  validity?: {
    days?: number;

    dateTo?: string;
  };
};
export type PPOPricelistItem_CreateOrUpdate = {
  type: 'membership' | 'application' | 'applicationPcn' | 'ppomembership';

  name: string;

  applicationId?: string;

  price?: Price;

  pcn?: number;

  description?: string;

  ageFrom?: number;

  ageTo?: number | null;

  sex?: elementary_sex;

  validity?: {
    days?: number;

    dateTo?: string;
  };
};
export type Sequence = {
  _id?: string;

  value?: number;

  pattern?: string;
};
export type Price = {
  amount: number;

  amount_vat_excl: number;

  currency: 'EUR';

  vat_rate: number;
};
export type PaygateInfo = {
  redirect?: string;

  request?: Request;

  gpwebpay?: {
    url: string;

    params: Array<{
      name?: string;

      value?: string | number | number;
    }>;
  };
};
export type Courier_Message_Parameters = {
  subject: string;

  perex: string;

  widgets?: Array<{}>;
};
export type Courier_Recipient_Parameters = {
  targetPPO?: string;

  users?: Array<string>;

  userGroups?: Array<string>;

  apps?: Array<{
    appId: string;

    roles?: Array<string> | null;
  }>;

  favoriteItems?: Array<{
    appFavType: string;

    appItemId: string;
  }>;

  sportAthlets?: Array<{
    activeOnly?: boolean;
  }>;

  sportExperts?: Array<{
    activeOnly?: boolean;

    types?: Array<string>;
  }>;

  relatedPPOs?: Array<{
    status?: 'active' | 'inactive';

    crmroles?: Array<string>;
  }>;
};
export type Courier_Template = {
  _id?: string;

  appSpace?: string;

  sender?: string;

  senderAppId?: string | null;

  date_sent?: string;

  parameters?: {
    message_parameters?: Courier_Message_Parameters;

    recipient_parameters?: Courier_Recipient_Parameters;
  };
};
export type Courier_Template_Create = {
  message_parameters: Courier_Message_Parameters;

  recipient_parameters: Courier_Recipient_Parameters;
};
export type Courier_Message = {
  _id?: string;

  template_id?: string;

  user_id?: string;

  app_id?: string;

  appSpace?: string | null;

  sender?: string;

  subject?: string;

  perex?: string;

  widgets?: Array<{}>;

  date_sent?: string;

  date_first_read?: string | null;

  date_last_read?: string | null;

  read?: boolean;

  important?: boolean;

  organization?: {
    _id?: string;

    name?: string;

    organization_id?: string;

    organization_name?: string;
  };
};
export type User_Groups_List = Array<User_Group>;
export type User_Group = {
  _id?: string;

  name?: string;
};
export type User_Group_Create = {
  _id: string;

  name: string;
};
export type User_Group_Modify = {
  name?: string;
};
export type PPOContactInfo = {
  email?: string;

  www?: string;

  phone?: string;

  fax?: string;

  name?: string;

  country?: string;

  contactPerson?: string;

  address?: Address;
};
export type PPOTheme = {
  color?: {
    primary?: string;
  };
};
export type Invitation = {
  _id: elementary__id;

  appId: string;

  createdDT: string;

  ppo: string;

  title: string;

  description: string;

  email: elementary_email;

  callback: string;

  expiration: string | null;

  payload?: {};

  status?: string;

  sportnetId?: string;

  resolved?: {
    dt?: string;

    ip?: string | null;

    ips?: Array<string | null> | null;

    userAgent?: string | null;
  };

  pending?: {
    status?: string;

    sportnetId?: string;
  };

  exitUrl?: {
    accepted?: string;

    rejected?: string;
  };

  senderUser?: {
    sportnetId?: string;

    displayName?: string;
  };
};
export type InvitationPublic = {
  _id: elementary__id;

  createdDT: string;

  ppo: string;

  title: string;

  description: string;

  expiration: string | null;

  status?: string;

  exitUrl?: {
    accepted?: string;

    rejected?: string;
  };
};
export type InvitationUser = {
  _id: elementary__id;

  appId: string;

  createdDT: string;

  ppo: string;

  title: string;

  description: string;

  email: elementary_email;

  expiration: string | null;

  status?: string;

  sportnetId?: string;

  resolved?: {
    dt?: string;

    ip?: string | null;

    ips?: Array<string | null> | null;

    userAgent?: string | null;
  };

  exitUrl?: {
    accepted?: string;

    rejected?: string;
  };

  senderUser?: {
    sportnetId?: string;

    displayName?: string;
  };
};
export type PPOShortname = {
  org_profile_id?: string;

  org_profile_name?: string;

  org_name?: string;

  shortname?: string;
};
export type PPOShortnameCreate = {
  shortname: string;
};
export type PPOShortnameUpdate = {
  shortname?: string;
};
export type PPOMembership = {
  org_profile_id?: string;

  org_profile_name?: string;

  org_name?: string;

  valid_from?: string;

  valid_to?: string;

  status?: PPOMembershipStatus;
};
export type PPOMembershipStatus =
  | 'active'
  | 'inactive'
  | 'cancelled'
  | 'excluded';
export type PPOMembershipCreate = {
  status: PPOMembershipStatus;

  valid_from: string;

  valid_to: string;
};
export type PPOMembershipUpdate = {
  status?: PPOMembershipStatus;

  valid_from?: string;

  valid_to?: string;
};
export type PPOContactPerson = {
  sportnetId?: string;

  name?: string;

  surname?: string;

  titles?: {
    before: string;

    after: string;
  };

  email?: Array<string>;

  phone?: Array<string>;

  position?: string;
};
export type PPOContactPersonCreate = {
  sportnetId: string | null;

  name: string;

  surname: string;

  titles?: {
    before: string;

    after: string;
  };

  email?: Array<string>;

  phone?: Array<string>;

  position: string;
};
export type PPOContactPersonUpdate = {
  name?: string;

  surname?: string;

  titles?: {
    before: string;

    after: string;
  };

  email?: Array<string>;

  phone?: Array<string>;

  position?: string;
};
export type InvoiceItem_Create = {
  _id?: string;

  name: string;

  subtitle?: string;

  quantity: number;

  totalPrice: Price;

  unitPrice?: Price;

  appId: string;

  createdDT?: string;

  status?: 'paid' | 'unpaid' | 'storno';

  payload?: {};

  payments?: Array<{
    method: 'SPORTNET_PAYGATE' | 'OTHER';

    amount: number;

    paymentDT: string;

    payload?: {};
  }>;
};
export type AutocompleteHighLight = {
  path: string;

  texts: Array<{
    value: string;

    type: string;
  }>;
};
export type FavoriteItem = {
  _id: string;

  appId: string;

  appSpace: string;

  appFavType: string;

  appItemId: string;

  title: string;

  subtitle?: string;

  image?: string;

  data?: {};

  createdDT?: string;
};
export type FavoriteItemCreate = {
  appSpace: string;

  appFavType: string;

  appItemId: string;

  title: string;

  subtitle?: string;

  image?: string;

  data?: {};
};
export type MediaManagerPhoto = {
  media_url: string;

  public_url: string;

  dimensions?: {
    X: number;

    Y: number;

    H: number;

    W: number;
  };
};
export type UserBio = {
  photo?: MediaManagerPhoto;

  perex?: string;

  facebook?: string;

  twitter?: string;

  instagram?: string;

  youtube?: string;

  widgets?: Array<Widget>;
};
export type UserBio_CreateOrUpdate = {
  photo?: MediaManagerPhoto;

  org_profile_id: string;

  perex?: string;

  facebook?: string;

  twitter?: string;

  instagram?: string;

  youtube?: string;

  widgets?: Array<Widget>;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class CoreApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class CoreApi {
  protected baseUrl: string = 'https://api.sportnet.online/v1';
  protected headers: { name: string; value: string }[] = [];
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Update or Create Push token. Push token is assigned to sportnet user, app_id and appSpace (from authorization).
   * @method
   * @name CoreApi#manageUserPushToken
   */
  manageUserPushToken(
    parameters: {},
    body: PushToken,
  ): Promise<{
    _id: elementary__id;

    tokenId: string;

    token: string;

    platform: 'ANDROID' | 'IOS' | 'WEB';

    type: 'DEBUG' | 'RELEASE';

    name?: string;

    badge?: number;
  }> {
    let path = '/me/push-tokens';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOProfiles
   */
  organizationPPOProfiles(
    parameters: {
      ids?: Array<string>;
      sectors?: Array<string>;
      type?: Array<string>;
      q?: string;
    } = {},
  ): Promise<{
    items: Array<OrganizationProfile>;
  }> {
    let path = '/ppo';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['sectors'] !== 'undefined') {
      queryParameters['sectors'] = parameters['sectors'];
    }

    queryParameters['sectors'] = this.transformParameter(
      queryParameters['sectors'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOProfile
   */
  organizationPPOProfile(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    name?: string;

    country?: string;

    type?: string;

    sectors?: Array<{
      category?: 'sport';

      itemId?: string;

      sectorId?: string;

      matricityPPO?: string;

      _id?: string;
    }>;

    sport?: string;

    status?: string;

    parent_relations?: Array<OrganizationProfile2>;

    matricity_relations?: Array<OrganizationProfile2>;

    organization_id?: string;

    organization_name?: string;

    logo_public_url?: string | null;

    favicon_public_url?: string | null;

    shortNames?: Array<OrganizationShortName>;

    createdBy?: {
      type?: string;

      payload?: {};
    };

    professional?: boolean;

    seasonCategories?: Array<{}>;
  }> {
    let path = '/ppo/{appSpace}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Zoznam transferov konkretnej PPO z pohladu zvazu. Zatial su podporovane len futbalsfz.sk a len sektor sport:futbal:futbal
   * @method
   * @name CoreApi#listTransfers
   */
  listTransfers(
    appSpace: 'futbalsfz.sk',
    sectorId: 'sport:futbal:futbal',
    parameters: {
      limit?: number;
      offset?: number;
      transferType?: 'hosting' | 'transfer';
      gender?: 'M' | 'F';
      age?: '18+' | '18-';
      dateFrom?: string;
      dateTo?: string;
      ppo?: string;
      ppoDir?: 'in' | 'out' | 'both';
    } = {},
  ): Promise<{
    total?: number;

    limit?: number;

    offset?: number;

    next_offset?: number | null;

    transfers?: Array<{
      _id: string;

      name?: string;

      surname?: string;

      family_name?: string;

      titles?: {
        before?: string;

        after?: string;
      };

      deleteAfter?: string;

      photo?: {
        token?: string;

        public?: boolean;

        public_url?: string;
      };

      birthyear?: number;

      age?: number;

      sex?: elementary_sex;

      citizenship?: string;

      transfer?: UserSportOrg;
    }>;
  }> {
    let path = '/ppo/{appSpace}/transfers/{sectorId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['transferType'] !== 'undefined') {
      queryParameters['transferType'] = parameters['transferType'];
    }

    queryParameters['transferType'] = this.transformParameter(
      queryParameters['transferType'],
    );

    if (typeof parameters['gender'] !== 'undefined') {
      queryParameters['gender'] = parameters['gender'];
    }

    queryParameters['gender'] = this.transformParameter(
      queryParameters['gender'],
    );

    if (typeof parameters['age'] !== 'undefined') {
      queryParameters['age'] = parameters['age'];
    }

    queryParameters['age'] = this.transformParameter(queryParameters['age']);

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['ppo'] !== 'undefined') {
      queryParameters['ppo'] = parameters['ppo'];
    }

    queryParameters['ppo'] = this.transformParameter(queryParameters['ppo']);

    queryParameters['ppoDir'] = 'both';

    if (typeof parameters['ppoDir'] !== 'undefined') {
      queryParameters['ppoDir'] = parameters['ppoDir'];
    }

    queryParameters['ppoDir'] = this.transformParameter(
      queryParameters['ppoDir'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sectorId}', sectorId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOListRelatedPPOs
   */
  organizationPPOListRelatedPPOs(
    appSpace: string,
    parameters: {
      q?: string;
      ppoType?: Array<string>;
      relationStatus?: string;
      sports?: Array<string>;
      with?: Array<'shortNames'>;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    total?: number;

    limit?: number;

    offset?: number;

    next_offset?: number | null;

    items?: Array<{
      _id?: string;

      name?: string;

      country?: string;

      type?: string;

      sectors?: Array<{
        category?: 'sport';

        itemId?: string;

        sectorId?: string;

        matricityPPO?: string;

        _id?: string;
      }>;

      sport?: string;

      status?: string;

      parent_relations?: Array<OrganizationProfile2>;

      matricity_relations?: Array<OrganizationProfile2>;

      organization_id?: string;

      organization_name?: string;

      logo_public_url?: string | null;

      favicon_public_url?: string | null;

      shortNames?: Array<OrganizationShortName>;

      relation?: OrganizationProfileRelation;
    }>;
  }> {
    let path = '/ppo/{appSpace}/related-ppos';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['ppoType'] !== 'undefined') {
      queryParameters['ppoType'] = parameters['ppoType'];
    }

    queryParameters['ppoType'] = this.transformParameter(
      queryParameters['ppoType'],
    );

    if (typeof parameters['relationStatus'] !== 'undefined') {
      queryParameters['relationStatus'] = parameters['relationStatus'];
    }

    queryParameters['relationStatus'] = this.transformParameter(
      queryParameters['relationStatus'],
    );

    if (typeof parameters['sports'] !== 'undefined') {
      queryParameters['sports'] = parameters['sports'];
    }

    queryParameters['sports'] = this.transformParameter(
      queryParameters['sports'],
    );

    if (typeof parameters['with'] !== 'undefined') {
      queryParameters['with'] = parameters['with'];
    }

    queryParameters['with'] = this.transformParameter(queryParameters['with']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationRelatedPPO
   */
  organizationRelatedPPO(
    appSpace: string,
    ppo: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    name?: string;

    country?: string;

    type?: string;

    sectors?: Array<{
      category?: 'sport';

      itemId?: string;

      sectorId?: string;

      matricityPPO?: string;

      _id?: string;
    }>;

    sport?: string;

    status?: string;

    parent_relations?: Array<OrganizationProfile2>;

    matricity_relations?: Array<OrganizationProfile2>;

    organization_id?: string;

    organization_name?: string;

    logo_public_url?: string | null;

    favicon_public_url?: string | null;

    shortNames?: Array<OrganizationShortName>;

    relation?: OrganizationProfileRelation;
  }> {
    let path = '/ppo/{appSpace}/related-ppos/{ppo}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{ppo}', ppo.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#getPPORelationsStructure
   */
  getPPORelationsStructure(
    appSpace: string,
    parameters: {
      ppoType?: string;
    } = {},
  ): Promise<{
    items: Array<OrganizationProfileStructureItem>;
  }> {
    let path = '/ppo/{appSpace}/relations-structure';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ppoType'] !== 'undefined') {
      queryParameters['ppoType'] = parameters['ppoType'];
    }

    queryParameters['ppoType'] = this.transformParameter(
      queryParameters['ppoType'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#getPPOLicensesIssuer
   */
  getPPOLicensesIssuer(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<IssuerLicense>;
  }> {
    let path = '/ppo/{appSpace}/licenses-issuer';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOGDPRBody
   */
  organizationPPOGDPRBody(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    gdpr?: string;
  }> {
    let path = '/ppo/{appSpace}/gdpr';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOCookiesSettings
   */
  organizationPPOCookiesSettings(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    cookies?: {
      additionalText?: string;
    };
  }> {
    let path = '/ppo/{appSpace}/cookies';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOContactInfo
   */
  organizationPPOContactInfo(
    appSpace: string,
    parameters: {} = {},
  ): Promise<PPOContactInfo> {
    let path = '/ppo/{appSpace}/contact-info';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOContactPersons
   */
  organizationPPOContactPersons(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    contactPersons?: Array<{
      _id: elementary__id;

      sportnetId?: string;

      name?: string;

      surname?: string;

      titles?: {
        before: string;

        after: string;
      };

      email?: Array<string>;

      phone?: Array<string>;

      position?: string;
    }>;
  }> {
    let path = '/ppo/{appSpace}/contact-persons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOTheme
   */
  organizationPPOTheme(
    appSpace: string,
    parameters: {} = {},
  ): Promise<PPOTheme> {
    let path = '/ppo/{appSpace}/theme';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOUsers
   */
  organizationPPOUsers(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
      relatedTo?: string;
      q?: string;
      autocomplete?: string;
      affiliationDateFrom?: string;
      affiliationDateTo?: string;
      birthdateFrom?: string;
      birthdateTo?: string;
      affiliationCategory?: 'sport_orgs' | 'sport_expert_orgs';
      competenceType?: string;
      affiliationActive?: boolean;
      membershipActive?: boolean;
      bioActive?: boolean;
      sportSector?: string;
      ids?: Array<string>;
      regnr?: string;
      sorter?: '_id' | '-_id' | 'person' | '-person';
    } = {},
  ): Promise<{
    total?: number;

    limit?: number;

    offset?: number;

    next_offset?: number | null;

    users: Array<{
      _id: string;

      name?: string;

      surname?: string;

      family_name?: string;

      titles?: {
        before?: string;

        after?: string;
      };

      deleteAfter?: string;

      photo?: {
        token?: string;

        public?: boolean;

        public_url?: string;
      };

      membership?: {
        regnr?: string;
      };

      sport_orgs?: Array<{
        type?: string;

        org_id?: string;

        org_name?: string;

        org_profile_id?: string;

        org_profile_name?: string;

        source_ppo?: string;

        source_ppo_name?: string;

        sector?: Sector;

        competence_type?: string;

        date_from?: string;

        date_to?: string | null;

        transfer_type?: string;

        valid_to?: string | null;
      }>;

      sport_expert_orgs?: Array<UserSportExpertOrg>;

      regnrs?: Array<{}>;

      bio?: Array<{
        _id: string;

        org_id: string;

        org_name: string;

        org_profile_id: string;

        org_profile_name: string;

        photo?: MediaManagerPhoto;

        perex?: string;

        facebook?: string;

        twitter?: string;

        instagram?: string;

        youtube?: string;

        widgets?: Array<Widget>;
      }>;

      awards?: Array<{
        _id: elementary__id;

        org_profile_id?: string;

        sport?: string;

        sport_sector?: string;

        award_type?: string;

        issue_date?: string;

        issued_by?: string;

        created_datetime?: string;

        org_id?: string;
      }>;

      custom?: Array<{
        _id: elementary__id;

        org_profile_id: string;

        label: string;

        value: string;
      }>;

      formalPhoto?: UserFormalPhoto;

      birthyear?: number;

      age?: number;

      sex?: elementary_sex;

      citizenship?: string;

      highlights?: Array<{
        path: string;

        texts: Array<{
          value: string;

          type: string;
        }>;
      }>;
    }>;
  }> {
    let path = '/ppo/{appSpace}/users';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['relatedTo'] !== 'undefined') {
      queryParameters['relatedTo'] = parameters['relatedTo'];
    }

    queryParameters['relatedTo'] = this.transformParameter(
      queryParameters['relatedTo'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['autocomplete'] !== 'undefined') {
      queryParameters['autocomplete'] = parameters['autocomplete'];
    }

    queryParameters['autocomplete'] = this.transformParameter(
      queryParameters['autocomplete'],
    );

    if (typeof parameters['affiliationDateFrom'] !== 'undefined') {
      queryParameters['affiliationDateFrom'] =
        parameters['affiliationDateFrom'];
    }

    queryParameters['affiliationDateFrom'] = this.transformParameter(
      queryParameters['affiliationDateFrom'],
    );

    if (typeof parameters['affiliationDateTo'] !== 'undefined') {
      queryParameters['affiliationDateTo'] = parameters['affiliationDateTo'];
    }

    queryParameters['affiliationDateTo'] = this.transformParameter(
      queryParameters['affiliationDateTo'],
    );

    if (typeof parameters['birthdateFrom'] !== 'undefined') {
      queryParameters['birthdateFrom'] = parameters['birthdateFrom'];
    }

    queryParameters['birthdateFrom'] = this.transformParameter(
      queryParameters['birthdateFrom'],
    );

    if (typeof parameters['birthdateTo'] !== 'undefined') {
      queryParameters['birthdateTo'] = parameters['birthdateTo'];
    }

    queryParameters['birthdateTo'] = this.transformParameter(
      queryParameters['birthdateTo'],
    );

    if (typeof parameters['affiliationCategory'] !== 'undefined') {
      queryParameters['affiliationCategory'] =
        parameters['affiliationCategory'];
    }

    queryParameters['affiliationCategory'] = this.transformParameter(
      queryParameters['affiliationCategory'],
    );

    if (typeof parameters['competenceType'] !== 'undefined') {
      queryParameters['competenceType'] = parameters['competenceType'];
    }

    queryParameters['competenceType'] = this.transformParameter(
      queryParameters['competenceType'],
    );

    queryParameters['affiliationActive'] = true;

    if (typeof parameters['affiliationActive'] !== 'undefined') {
      queryParameters['affiliationActive'] = parameters['affiliationActive'];
    }

    queryParameters['affiliationActive'] = this.transformParameter(
      queryParameters['affiliationActive'],
    );

    queryParameters['membershipActive'] = false;

    if (typeof parameters['membershipActive'] !== 'undefined') {
      queryParameters['membershipActive'] = parameters['membershipActive'];
    }

    queryParameters['membershipActive'] = this.transformParameter(
      queryParameters['membershipActive'],
    );

    queryParameters['bioActive'] = false;

    if (typeof parameters['bioActive'] !== 'undefined') {
      queryParameters['bioActive'] = parameters['bioActive'];
    }

    queryParameters['bioActive'] = this.transformParameter(
      queryParameters['bioActive'],
    );

    if (typeof parameters['sportSector'] !== 'undefined') {
      queryParameters['sportSector'] = parameters['sportSector'];
    }

    queryParameters['sportSector'] = this.transformParameter(
      queryParameters['sportSector'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['regnr'] !== 'undefined') {
      queryParameters['regnr'] = parameters['regnr'];
    }

    queryParameters['regnr'] = this.transformParameter(
      queryParameters['regnr'],
    );

    queryParameters['sorter'] = 'person';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOUser
   */
  organizationPPOUser(
    appSpace: string,
    userId: string,
    parameters: {
      withRelatedAffiliations?: boolean;
    } = {},
  ): Promise<{
    _id: string;

    name?: string;

    surname?: string;

    family_name?: string;

    titles?: {
      before?: string;

      after?: string;
    };

    deleteAfter?: string;

    photo?: {
      token?: string;

      public?: boolean;

      public_url?: string;
    };

    membership?: {
      regnr?: string;
    };

    sport_orgs?: Array<{
      type?: string;

      org_id?: string;

      org_name?: string;

      org_profile_id?: string;

      org_profile_name?: string;

      source_ppo?: string;

      source_ppo_name?: string;

      sector?: Sector;

      competence_type?: string;

      date_from?: string;

      date_to?: string | null;

      transfer_type?: string;

      valid_to?: string | null;
    }>;

    sport_expert_orgs?: Array<UserSportExpertOrg>;

    regnrs?: Array<{}>;

    bio?: Array<{
      _id: string;

      org_id: string;

      org_name: string;

      org_profile_id: string;

      org_profile_name: string;

      photo?: MediaManagerPhoto;

      perex?: string;

      facebook?: string;

      twitter?: string;

      instagram?: string;

      youtube?: string;

      widgets?: Array<Widget>;
    }>;

    awards?: Array<{
      _id: elementary__id;

      org_profile_id?: string;

      sport?: string;

      sport_sector?: string;

      award_type?: string;

      issue_date?: string;

      issued_by?: string;

      created_datetime?: string;

      org_id?: string;
    }>;

    custom?: Array<{
      _id: elementary__id;

      org_profile_id: string;

      label: string;

      value: string;
    }>;

    formalPhoto?: UserFormalPhoto;

    birthyear?: number;

    age?: number;

    deathdate?: string;

    sex?: elementary_sex;

    citizenship?: string;

    sport_expert_licenses?: Array<{}>;

    ppoRelation: UserPPORelation;

    isPublic?: boolean;

    issfIsPublic?: boolean;
  }> {
    let path = '/ppo/{appSpace}/users/{userId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['withRelatedAffiliations'] = false;

    if (typeof parameters['withRelatedAffiliations'] !== 'undefined') {
      queryParameters['withRelatedAffiliations'] =
        parameters['withRelatedAffiliations'];
    }

    queryParameters['withRelatedAffiliations'] = this.transformParameter(
      queryParameters['withRelatedAffiliations'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{userId}', userId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOUserSportOrgs
   */
  organizationPPOUserSportOrgs(
    appSpace: string,
    userId: string,
    parameters: {
      ppoTypes?: Array<string>;
    } = {},
  ): Promise<{
    items: Array<{
      type?: string;

      isActive: boolean;

      org_id?: string;

      org_name?: string;

      org_profile_id?: string;

      org_profile_name?: string;

      source_ppo?: string;

      source_ppo_name?: string;

      sector?: Sector;

      competence_type?: string;

      date_from?: string;

      date_to?: string | null;

      transfer_type?: string;

      valid_to?: string | null;
    }>;
  }> {
    let path = '/ppo/{appSpace}/users/{userId}/sport-orgs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ppoTypes'] !== 'undefined') {
      queryParameters['ppoTypes'] = parameters['ppoTypes'];
    }

    queryParameters['ppoTypes'] = this.transformParameter(
      queryParameters['ppoTypes'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{userId}', userId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOUserSportExpertOrgs
   */
  organizationPPOUserSportExpertOrgs(
    appSpace: string,
    userId: string,
    parameters: {
      ppoTypes?: Array<string>;
    } = {},
  ): Promise<{
    items: Array<{
      _id: elementary__id;

      isActive: boolean;

      org_id?: string;

      org_profile_id?: string;

      org_name?: string;

      org_profile_name?: string;

      sector?: Sector;

      competence_type?: string;

      legal_form?: string;

      license_id?: string;

      license?: UserLicense;

      date_from?: string;

      date_to?: string | null;

      valid_to?: string | null;
    }>;
  }> {
    let path = '/ppo/{appSpace}/users/{userId}/sport-expert-orgs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ppoTypes'] !== 'undefined') {
      queryParameters['ppoTypes'] = parameters['ppoTypes'];
    }

    queryParameters['ppoTypes'] = this.transformParameter(
      queryParameters['ppoTypes'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{userId}', userId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOUserLicenses
   */
  organizationPPOUserLicenses(
    appSpace: string,
    userId: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id: elementary__id;

      org_profile_id?: string;

      type?: string;

      name?: string;

      documentnr?: string;

      valid_from?: string;

      valid_to?: string | null;

      issued_by_po?: string;

      verified_by_person?: string;

      verified_by_position?: string;

      registration_date?: string;

      org_name?: string;

      org_profile_name?: string;

      note?: string;

      files?: Array<FileObject>;
    }>;
  }> {
    let path = '/ppo/{appSpace}/users/{userId}/licenses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{userId}', userId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationPPOUserTransfers
   */
  organizationPPOUserTransfers(
    appSpace: string,
    userId: string,
    parameters: {} = {},
  ): Promise<{
    items?: Array<UserTransfer>;
  }> {
    let path = '/ppo/{appSpace}/users/{userId}/transfers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{userId}', userId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#ppoOrganizationBankAccounts
   */
  ppoOrganizationBankAccounts(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    items?: Array<{
      _id: elementary__id;

      status?: elementary_itemStatus;

      IBAN?: string;

      type?: string;
    }>;
  }> {
    let path = '/ppo/{appSpace}/organization/bank-accounts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#ppoOrganizationAddresses
   */
  ppoOrganizationAddresses(
    appSpace: string,
    parameters: {
      type?: Array<string>;
    } = {},
  ): Promise<{
    items?: Array<{
      _id: elementary__id;

      status?: elementary_itemStatus;

      street?: string;

      number?: string | number;

      conscription_number?: string | number;

      city?: string;

      zip?: string;

      country?: string;

      name?: string;

      surname?: string;

      company?: string;

      type?: string;

      location?: GeoLocation;
    }>;
  }> {
    let path = '/ppo/{appSpace}/organization/addresses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#organizationInvoiceProfile
   */
  organizationInvoiceProfile(
    orgId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    full_name?: string;

    name?: string;

    legal_form?: string;

    business_id?: string;

    vat_nr?: string | null;

    vat_foreign_trade_info?: string;

    vat_alternatives?: Array<string>;

    email?: string;

    www?: string;

    phone?: string;

    fax?: string;

    contact_person?: string;

    statutory?: string;

    logo_public_url?: string | null;

    favicon_public_url?: string | null;

    invoice_address?: Address;

    bank_account?: BankAccount;
  }> {
    let path = '/organizations/{orgId}/invoice-profile';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#ppoInvoiceProfile
   */
  ppoInvoiceProfile(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    full_name?: string;

    name?: string;

    legal_form?: string;

    business_id?: string;

    vat_nr?: string | null;

    vat_foreign_trade_info?: string;

    vat_alternatives?: Array<string>;

    email?: string;

    www?: string;

    phone?: string;

    fax?: string;

    contact_person?: string;

    statutory?: string;

    logo_public_url?: string | null;

    favicon_public_url?: string | null;

    invoice_address?: Address;

    bank_account?: BankAccount;
  }> {
    let path = '/ppo/{appSpace}/invoice-profile';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#getPublicAppSpaceGroups
   */
  getPublicAppSpaceGroups(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    groups?: User_Groups_List;
  }> {
    let path = '/ppo/{appSpace}/user-groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#getOrganizations
   */
  getOrganizations(
    parameters: {
      q?: string;
      businessId?: string;
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<{
    total?: number;

    limit?: number;

    offset?: number;

    next_offset?: number | null;

    organizations?: Array<OrganizationWithProfiles>;
  }> {
    let path = '/organizations';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['businessId'] !== 'undefined') {
      queryParameters['businessId'] = parameters['businessId'];
    }

    queryParameters['businessId'] = this.transformParameter(
      queryParameters['businessId'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#getOrganizationProfiles
   */
  getOrganizationProfiles(
    orgId: string,
    parameters: {} = {},
  ): Promise<{
    items?: Array<{
      _id?: string;

      name?: string;

      country?: string;

      type?: string;

      sectors?: Array<{
        category?: 'sport';

        itemId?: string;

        sectorId?: string;

        matricityPPO?: string;

        _id?: string;
      }>;

      sport?: string;

      status?: string;

      parent_relations?: Array<OrganizationProfile2>;

      matricity_relations?: Array<OrganizationProfile2>;

      organization_id?: string;

      organization_name?: string;

      logo_public_url?: string | null;

      favicon_public_url?: string | null;

      shortNames?: Array<OrganizationShortName>;

      relations?: Array<OrganizationProfileRelation>;
    }>;
  }> {
    let path = '/organizations/{orgId}/profiles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get codelist ids
   * @method
   * @name CoreApi#listCodelists
   */
  listCodelists(parameters: {} = {}): Promise<{
    codelists?: Array<string>;
  }> {
    let path = '/codelist';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get codelist
   * @method
   * @name CoreApi#getCodelist
   */
  getCodelist(codelist: string, parameters: {} = {}): Promise<Codelist> {
    let path = '/codelist/{codelist}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{codelist}', codelist.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get codelist
   * @method
   * @name CoreApi#getCodelistSector
   */
  getCodelistSector(
    codelist: string,
    codelistItem: string,
    parameters: {} = {},
  ): Promise<Codelist> {
    let path = '/codelist/{codelist}/{codelistItem}/sector';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{codelist}', codelist.toString());

    path = path.replace('{codelistItem}', codelistItem.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get codelist
   * @method
   * @name CoreApi#getCodelistSectorDiscipline
   */
  getCodelistSectorDiscipline(
    codelist: string,
    codelistItem: string,
    sectorItem: string,
    parameters: {} = {},
  ): Promise<Codelist> {
    let path =
      '/codelist/{codelist}/{codelistItem}/sector/{sectorItem}/discipline';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{codelist}', codelist.toString());

    path = path.replace('{codelistItem}', codelistItem.toString());

    path = path.replace('{sectorItem}', sectorItem.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#me
   */
  me(
    parameters: {
      externalProfile?: string;
      withGroups?: boolean;
    } = {},
  ): Promise<UserPublicProfile> {
    let path = '/me';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['externalProfile'] !== 'undefined') {
      queryParameters['externalProfile'] = parameters['externalProfile'];
    }

    queryParameters['externalProfile'] = this.transformParameter(
      queryParameters['externalProfile'],
    );

    queryParameters['withGroups'] = false;

    if (typeof parameters['withGroups'] !== 'undefined') {
      queryParameters['withGroups'] = parameters['withGroups'];
    }

    queryParameters['withGroups'] = this.transformParameter(
      queryParameters['withGroups'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meFavorites
   */
  meFavorites(
    parameters: {
      offset?: number;
      limit?: number;
      appId?: string;
      appItemId?: Array<string>;
      appFavType?: Array<string>;
      appSpace?: Array<string>;
    } = {},
  ): Promise<{
    total?: number;

    limit?: number;

    offset?: number;

    next_offset?: number | null;

    favorites?: Array<FavoriteItem>;
  }> {
    let path = '/me/favorites';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['appId'] !== 'undefined') {
      queryParameters['appId'] = parameters['appId'];
    }

    queryParameters['appId'] = this.transformParameter(
      queryParameters['appId'],
    );

    if (typeof parameters['appItemId'] !== 'undefined') {
      queryParameters['appItemId'] = parameters['appItemId'];
    }

    queryParameters['appItemId'] = this.transformParameter(
      queryParameters['appItemId'],
    );

    if (typeof parameters['appFavType'] !== 'undefined') {
      queryParameters['appFavType'] = parameters['appFavType'];
    }

    queryParameters['appFavType'] = this.transformParameter(
      queryParameters['appFavType'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meFavoritesCreateItem
   */
  meFavoritesCreateItem(
    parameters: {},
    body: {
      favorites: Array<FavoriteItemCreate>;
    },
  ): Promise<FavoriteItem> {
    let path = '/me/favorites';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Reset (remove) all favorite items created by appId / appSpace - based on token.
   * @method
   * @name CoreApi#meFavoritesResetForApp
   */
  meFavoritesResetForApp(parameters: {} = {}): Promise<{}> {
    let path = '/me/favorites';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * System checks the appId of the item, unless token belongs to privileged app (my.sportnet.online for example).
   * @method
   * @name CoreApi#meFavoritesRemoveItem
   */
  meFavoritesRemoveItem(favId: string, parameters: {} = {}): Promise<{}> {
    let path = '/me/favorites/{favId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{favId}', favId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meInvitations
   */
  meInvitations(
    parameters: {
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<{
    total?: number;

    limit?: number;

    offset?: number;

    next_offset?: number | null;

    invitations?: Array<InvitationUser>;
  }> {
    let path = '/me/invitations';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meRelations
   */
  meRelations(parameters: {} = {}): Promise<{
    relations?: Array<{
      relatedId: string;

      type: string;

      user?: UserPublicProfile;
    }>;
  }> {
    let path = '/me/relations';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meNewRelationInvitation
   */
  meNewRelationInvitation(
    parameters: {},
    body: {
      email: string;

      type: string;

      relatedSportnetIds: Array<string>;
    },
  ): Promise<{}> {
    let path = '/me/relations-invitation';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meRelationTakeover
   */
  meRelationTakeover(
    relatedId: string,
    parameters: {} = {},
  ): Promise<{
    access_token?: string;
  }> {
    let path = '/me/relations/{relatedId}/takeover';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{relatedId}', relatedId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meMessages
   */
  meMessages(
    parameters: {
      offset?: number;
      limit?: number;
      q?: string;
      read?: boolean;
      important?: boolean;
    } = {},
  ): Promise<{
    total?: number;

    limit?: number;

    offset?: number;

    next_offset?: number | null;

    messages?: Array<Courier_Message>;
  }> {
    let path = '/me/messages';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['read'] !== 'undefined') {
      queryParameters['read'] = parameters['read'];
    }

    queryParameters['read'] = this.transformParameter(queryParameters['read']);

    if (typeof parameters['important'] !== 'undefined') {
      queryParameters['important'] = parameters['important'];
    }

    queryParameters['important'] = this.transformParameter(
      queryParameters['important'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meMarkAllMessagesAsRead
   */
  meMarkAllMessagesAsRead(parameters: {} = {}): Promise<{
    matchedCount?: number;

    modifiedCount?: number;
  }> {
    let path = '/me/messages';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meUnreadMessagesCount
   */
  meUnreadMessagesCount(parameters: {} = {}): Promise<{
    count?: number;
  }> {
    let path = '/me/messages-unread-count';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meMessageById
   */
  meMessageById(
    courierMessageIds: Array<string>,
    parameters: {} = {},
  ): Promise<Courier_Message> {
    let path = '/me/messages/{courierMessageIds}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{courierMessageIds}', courierMessageIds.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meMessageStatusChange
   */
  meMessageStatusChange(
    courierMessageIds: Array<string>,
    parameters: {} = {},
    body: {
      read?: boolean;

      important?: boolean;
    },
  ): Promise<Courier_Message> {
    let path = '/me/messages/{courierMessageIds}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{courierMessageIds}', courierMessageIds.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meUserGroups
   */
  meUserGroups(parameters: {} = {}): Promise<{
    groups?: Array<{
      ppo?: string;

      group?: User_Group;

      organization?: OrganizationProfile;
    }>;
  }> {
    let path = '/me/groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meRequests
   */
  meRequests(parameters: {} = {}): Promise<{
    requests?: Array<Request>;
  }> {
    let path = '/me/requests';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meInvoices
   */
  meInvoices(parameters: {} = {}): Promise<{
    invoices?: Array<InvoiceItem>;
  }> {
    let path = '/me/invoices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Metoda pre ziskanie download ID pdf faktury
   * @method
   * @name CoreApi#meInvoicePdf
   */
  meInvoicePdf(
    invoiceId: string,
    parameters: {} = {},
  ): Promise<{
    download_id: string;
  }> {
    let path = '/me/invoices/{invoiceId}/pdf';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invoiceId}', invoiceId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meAwards
   */
  meAwards(parameters: {} = {}): Promise<{
    items?: Array<{
      _id: elementary__id;

      org_profile_id?: string;

      sport?: string;

      sport_sector?: string;

      award_type?: string;

      issue_date?: string;

      issued_by?: string;

      created_datetime?: string;

      org_id?: string;
    }>;
  }> {
    let path = '/me/awards';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meAwardsGet
   */
  meAwardsGet(
    itemId: string,
    parameters: {} = {},
  ): Promise<{
    _id: elementary__id;

    org_profile_id?: string;

    sport?: string;

    sport_sector?: string;

    award_type?: string;

    issue_date?: string;

    issued_by?: string;

    created_datetime?: string;

    org_id?: string;
  }> {
    let path = '/me/awards/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meMembership
   */
  meMembership(parameters: {} = {}): Promise<{
    items?: Array<{
      _id: elementary__id;

      org_profile_id?: string;

      org_profile_name?: string;

      org_name?: string;

      valid_from?: string;

      valid_to?: string;

      status?: UserMembershipStatus;
    }>;
  }> {
    let path = '/me/membership';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meMembershipGet
   */
  meMembershipGet(
    itemId: string,
    parameters: {} = {},
  ): Promise<{
    _id: elementary__id;

    org_profile_id?: string;

    org_profile_name?: string;

    org_name?: string;

    valid_from?: string;

    valid_to?: string;

    status?: UserMembershipStatus;
  }> {
    let path = '/me/membership/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meRegnrs
   */
  meRegnrs(parameters: {} = {}): Promise<{
    items?: Array<{
      _id: elementary__id;

      org_profile_id?: string;

      org_profile_name?: string;

      org_name?: string;

      regnr?: string;
    }>;
  }> {
    let path = '/me/regnrs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meBios
   */
  meBios(parameters: {} = {}): Promise<{
    items?: Array<{
      _id: elementary__id;

      org_profile_id?: string;

      org_name?: string;

      org_profile_name?: string;

      name?: string;

      widgets?: Array<Widget>;
    }>;
  }> {
    let path = '/me/bios';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfiles
   */
  meProfiles(parameters: {} = {}): Promise<{
    external_profiles?: Array<UserExternalProfile>;
  }> {
    let path = '/me/profiles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfilesIssf
   */
  meProfilesIssf(parameters: {} = {}): Promise<UserExternalProfile> {
    let path = '/me/profiles/issf';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfilesIssfConnect
   */
  meProfilesIssfConnect(parameters: {
    code: string;
    redirectUri: string;
  }): Promise<UserExternalProfile> {
    let path = '/me/profiles/issf/connect';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['code'] === 'undefined') {
      throw new Error('Missing required parameter: code');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw new Error('Missing required parameter: redirectUri');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfilesIssfToken
   */
  meProfilesIssfToken(parameters: {} = {}): Promise<{
    access_token?: string;

    token_type?: string;
  }> {
    let path = '/me/profiles/issf/token';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfilesFacebookConnect
   */
  meProfilesFacebookConnect(
    parameters: {} = {},
    body: {
      access_token: string;
    },
  ): Promise<UserExternalProfile> {
    let path = '/me/profiles/facebook/connect';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfilesFacebookDisconnect
   */
  meProfilesFacebookDisconnect(parameters: {} = {}): Promise<{}> {
    let path = '/me/profiles/facebook';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfilesGoogleDisconnect
   */
  meProfilesGoogleDisconnect(parameters: {} = {}): Promise<{}> {
    let path = '/me/profiles/google';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfilesAppleDisconnect
   */
  meProfilesAppleDisconnect(parameters: {} = {}): Promise<{}> {
    let path = '/me/profiles/apple';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfile
   */
  meProfile(parameters: {} = {}): Promise<UserProfile> {
    let path = '/me/profile';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meProfileUpdate
   */
  meProfileUpdate(
    parameters: {},
    body: UserProfileUpdate,
  ): Promise<UserProfile> {
    let path = '/me/profile';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meAddresses
   */
  meAddresses(
    parameters: {
      type?: Array<string>;
    } = {},
  ): Promise<{
    items?: Array<{
      _id: elementary__id;

      status?: elementary_itemStatus;

      street?: string;

      number?: string | number;

      conscription_number?: string | number;

      city?: string;

      zip?: string;

      country?: string;

      name?: string;

      surname?: string;

      company?: string;

      type?: string;

      location?: GeoLocation;
    }>;
  }> {
    let path = '/me/addresses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meAddressesCreate
   */
  meAddressesCreate(
    parameters: {},
    body: AddressCreateOrUpdate,
  ): Promise<Address> {
    let path = '/me/addresses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meAddressesGet
   */
  meAddressesGet(
    itemId: string,
    parameters: {} = {},
  ): Promise<{
    _id: elementary__id;

    status?: elementary_itemStatus;

    street?: string;

    number?: string | number;

    conscription_number?: string | number;

    city?: string;

    zip?: string;

    country?: string;

    name?: string;

    surname?: string;

    company?: string;

    type?: string;

    location?: GeoLocation;
  }> {
    let path = '/me/addresses/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meAddressesUpdate
   */
  meAddressesUpdate(
    itemId: string,
    parameters: {},
    body: AddressCreateOrUpdate,
  ): Promise<{
    _id: elementary__id;

    status?: elementary_itemStatus;

    street?: string;

    number?: string | number;

    conscription_number?: string | number;

    city?: string;

    zip?: string;

    country?: string;

    name?: string;

    surname?: string;

    company?: string;

    type?: string;

    location?: GeoLocation;
  }> {
    let path = '/me/addresses/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meAddressesDelete
   */
  meAddressesDelete(itemId: string, parameters: {} = {}): Promise<{}> {
    let path = '/me/addresses/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meBankAccounts
   */
  meBankAccounts(parameters: {} = {}): Promise<{
    items?: Array<{
      _id: elementary__id;

      status?: elementary_itemStatus;

      IBAN?: string;

      type?: string;
    }>;
  }> {
    let path = '/me/bank-accounts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meBankAccountsCreate
   */
  meBankAccountsCreate(
    parameters: {},
    body: BankAccountCreateOrUpdate,
  ): Promise<{
    _id: elementary__id;

    status?: elementary_itemStatus;

    IBAN?: string;

    type?: string;
  }> {
    let path = '/me/bank-accounts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meBankAccountsGet
   */
  meBankAccountsGet(
    itemId: string,
    parameters: {} = {},
  ): Promise<{
    _id: elementary__id;

    status?: elementary_itemStatus;

    IBAN?: string;

    type?: string;
  }> {
    let path = '/me/bank-accounts/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meBankAccountsUpdate
   */
  meBankAccountsUpdate(
    itemId: string,
    parameters: {},
    body: BankAccountCreateOrUpdate,
  ): Promise<{
    _id: elementary__id;

    status?: elementary_itemStatus;

    IBAN?: string;

    type?: string;
  }> {
    let path = '/me/bank-accounts/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meBankAccountsDelete
   */
  meBankAccountsDelete(itemId: string, parameters: {} = {}): Promise<{}> {
    let path = '/me/bank-accounts/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meCustomFields
   */
  meCustomFields(parameters: {} = {}): Promise<{
    items?: Array<{
      _id: elementary__id;

      org_profile_id: string;

      label: string;

      value: string;
    }>;
  }> {
    let path = '/me/custom';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meCustomFieldsCreate
   */
  meCustomFieldsCreate(
    parameters: {},
    body: CustomFieldCreateOrUpdate,
  ): Promise<{
    _id: elementary__id;

    org_profile_id: string;

    label: string;

    value: string;
  }> {
    let path = '/me/custom';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meCustomFieldsGet
   */
  meCustomFieldsGet(
    itemId: string,
    parameters: {} = {},
  ): Promise<{
    _id: elementary__id;

    org_profile_id: string;

    label: string;

    value: string;
  }> {
    let path = '/me/custom/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meCustomFieldsUpdate
   */
  meCustomFieldsUpdate(
    itemId: string,
    parameters: {},
    body: CustomFieldCreateOrUpdate,
  ): Promise<{
    _id: elementary__id;

    org_profile_id: string;

    label: string;

    value: string;
  }> {
    let path = '/me/custom/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meCustomFieldsDelete
   */
  meCustomFieldsDelete(itemId: string, parameters: {} = {}): Promise<{}> {
    let path = '/me/custom/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
    * Store various app data for user. Data is scoped for user, appId and appSpace. appId and appSpace is read from access token.
Data is in schema of type object (key-value, where value can be any type).

    * @method
    * @name CoreApi#meAppDataGet
    */
  meAppDataGet(parameters: {} = {}): Promise<{}> {
    let path = '/me/appdata';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meAppDataReplace
   */
  meAppDataReplace(parameters: {}, body: {}): Promise<{}> {
    let path = '/me/appdata';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meAppDataUpdate
   */
  meAppDataUpdate(parameters: {}, body: {}): Promise<{}> {
    let path = '/me/appdata';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'PATCH',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meSportOrgs
   */
  meSportOrgs(parameters: {} = {}): Promise<{
    items?: Array<{
      _id: elementary__id;

      status?: elementary_itemStatus;

      org_id?: string;

      org_name?: string;

      org_profile_id?: string;

      org_profile_name?: string;

      source_ppo?: string;

      source_ppo_name?: string;

      sector?: Sector;

      competence_type?: string;

      date_from?: string;

      date_to?: string | null;

      transfer_type?: string;

      valid_to?: string | null;
    }>;
  }> {
    let path = '/me/sport-orgs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meSportOrgItem
   */
  meSportOrgItem(
    itemId: string,
    parameters: {} = {},
  ): Promise<{
    _id: elementary__id;

    status?: elementary_itemStatus;

    org_id?: string;

    org_name?: string;

    org_profile_id?: string;

    org_profile_name?: string;

    source_ppo?: string;

    source_ppo_name?: string;

    sector?: Sector;

    competence_type?: string;

    date_from?: string;

    date_to?: string | null;

    transfer_type?: string;

    valid_to?: string | null;
  }> {
    let path = '/me/sport-orgs/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meSportExpertOrgs
   */
  meSportExpertOrgs(parameters: {} = {}): Promise<{
    items?: Array<{
      _id: elementary__id;

      status?: elementary_itemStatus;

      org_id?: string;

      org_profile_id?: string;

      org_name?: string;

      org_profile_name?: string;

      sector?: Sector;

      competence_type?: string;

      legal_form?: string;

      license_id?: string;

      license?: UserLicense;

      date_from?: string;

      date_to?: string | null;

      valid_to?: string | null;
    }>;
  }> {
    let path = '/me/sport-expert-orgs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meSportExpertOrgItem
   */
  meSportExpertOrgItem(
    itemId: string,
    parameters: {} = {},
  ): Promise<{
    _id: elementary__id;

    status?: elementary_itemStatus;

    org_id?: string;

    org_profile_id?: string;

    org_name?: string;

    org_profile_name?: string;

    sector?: Sector;

    competence_type?: string;

    legal_form?: string;

    license_id?: string;

    license?: UserLicense;

    date_from?: string;

    date_to?: string | null;

    valid_to?: string | null;
  }> {
    let path = '/me/sport-expert-orgs/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meLicenses
   */
  meLicenses(parameters: {} = {}): Promise<{
    items?: Array<{
      _id: elementary__id;

      org_profile_id?: string;

      type?: string;

      name?: string;

      documentnr?: string;

      valid_from?: string;

      valid_to?: string | null;

      issued_by_po?: string;

      verified_by_person?: string;

      verified_by_position?: string;

      registration_date?: string;

      org_name?: string;

      org_profile_name?: string;

      note?: string;

      files?: Array<FileObject>;
    }>;
  }> {
    let path = '/me/licenses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meLicensesCreate
   */
  meLicensesCreate(
    parameters: {},
    body: UserLicenseCreateOrUpdate,
  ): Promise<{
    _id: elementary__id;

    org_profile_id?: string;

    type?: string;

    name?: string;

    documentnr?: string;

    valid_from?: string;

    valid_to?: string | null;

    issued_by_po?: string;

    verified_by_person?: string;

    verified_by_position?: string;

    registration_date?: string;

    org_name?: string;

    org_profile_name?: string;

    note?: string;

    files?: Array<FileObject>;
  }> {
    let path = '/me/licenses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
    * List of all user roles, in all appspaces, divided by app_id

    * @method
    * @name CoreApi#meAppSpaces
    */
  meAppSpaces(
    parameters: {
      expandApp?: boolean;
    } = {},
  ): Promise<{
    apps?: Array<{
      app_id?: string;

      app?: AppPublic;

      appspaces?: Array<AppSpaceUser>;
    }>;
  }> {
    let path = '/me/appspaces';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['expandApp'] = false;

    if (typeof parameters['expandApp'] !== 'undefined') {
      queryParameters['expandApp'] = parameters['expandApp'];
    }

    queryParameters['expandApp'] = this.transformParameter(
      queryParameters['expandApp'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * User role in appspace
   * @method
   * @name CoreApi#meAppSpacesForApp
   */
  meAppSpacesForApp(
    appId: string,
    parameters: {} = {},
  ): Promise<{
    appspaces?: Array<AppSpaceUser>;
  }> {
    let path = '/me/appspaces/{appId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * User role in ppo
   * @method
   * @name CoreApi#meAppInfoForAppSpace
   */
  meAppInfoForAppSpace(
    appId: string,
    appSpace: string,
    parameters: {} = {},
  ): Promise<AppSpaceUser> {
    let path = '/me/appspaces/{appId}/appspace/{appSpace}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * List of user's access tokens
   * @method
   * @name CoreApi#meAccessTokens
   */
  meAccessTokens(parameters: {} = {}): Promise<{
    accessTokens?: Array<{
      client_id?: string;

      token_type?: 'Bearer';

      expires_at?: number;

      expires_in?: number;

      expires_at_date?: string;

      user_id?: string;

      scopes?: Array<string>;

      userinfo?: UserPublicProfile;

      usergroups?: Array<{
        ppo?: string;

        group?: Array<string>;
      }>;

      appspace?: AppSpaceUser;

      org_info?: Organization;

      ppo_info?: OrganizationProfile;

      token?: string;

      appInfo?: AppPublic;
    }>;
  }> {
    let path = '/me/access-tokens';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Change user password
   * @method
   * @name CoreApi#mePasswd
   */
  mePasswd(
    parameters: {} = {},
    body: {
      old_password: string;

      new_password: string;
    },
  ): Promise<{}> {
    let path = '/me/passwd';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#mePhotoUpload
   */
  mePhotoUpload(
    parameters: {},
    form: {
      upload: File;
    },
  ): Promise<{}> {
    let path = '/me/photo';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }

  /**
   * Metoda pre vymazanie fotky uzivatela
   * @method
   * @name CoreApi#mePhotoDelete
   */
  mePhotoDelete(parameters: {} = {}): Promise<{}> {
    let path = '/me/photo';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name CoreApi#meDelete
   */
  meDelete(
    parameters: {} = {},
    body: {
      delete: boolean;
    },
  ): Promise<UserPublicProfile> {
    let path = '/me/delete';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
}

export default new CoreApi();
export { CoreApi };
